import { Button, Container, TextField, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React, { ChangeEvent, useState } from "react";
import { v4 } from "uuid";
import { RecipeEditor } from "../../components/RecipeEditor/RecipeEditor";
import { addRecipe, deleteRecipe, updateRecipe } from "../../firebase";
import { useRecipes } from "../../hooks/recipes";
import { Recipe } from "../../models/recipe";
import styles from "./RecipesView.module.css";

export const RecipesView: React.FC<RouteComponentProps> = () => {
  const recipes = useRecipes();
  const [filter, setFilter] = useState("");
  const [expandedRecipe, setExpandedRecipe] = React.useState<string | false>(false);

  const onChangeFilter = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFilter(value);
  };

  const toggleExpandedRecipe = (recipe: Recipe) => (
    event: ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpandedRecipe(isExpanded ? recipe.id : false);
  };

  const handleCreateRecipe = async () => {
    const id = v4();
    await addRecipe({
      id,
      name: "# Neues REZEPT",
      text: "",
      category: "# Neue Kategorie",
      photo: "",
      active: false,
    });

    setExpandedRecipe(id);

    window.scroll({ top: 0, behavior: "smooth" });
  };

  const handleUpdateRecipe = async (recipe: Recipe) => {
    await updateRecipe(recipe);
    setExpandedRecipe(false);
  };

  const handleDeleteRecipe = (recipe: Recipe) => async () => {
    await deleteRecipe(recipe);
    setExpandedRecipe(false);
  };

  return (
    <div className={styles.root}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Alle Rezepte
        </Typography>
        <TextField
          variant="outlined"
          label="Filter"
          value={filter}
          fullWidth
          style={{ marginBottom: "10px" }}
          onChange={onChangeFilter}
        />
        {recipes
          .filter(
            (recipe) =>
              !filter ||
              recipe.name.toLowerCase().includes(filter.toLowerCase()) ||
              recipe.text.toLowerCase().includes(filter.toLowerCase()) ||
              recipe.category.toLowerCase().includes(filter.toLowerCase()),
          )
          .map((recipe) => (
            <RecipeEditor
              key={recipe.id}
              recipe={recipe}
              onSave={handleUpdateRecipe}
              onDelete={handleDeleteRecipe(recipe)}
              expanded={expandedRecipe === recipe.id}
              toggleExpanded={toggleExpandedRecipe(recipe)}
            />
          ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateRecipe}
          style={{ marginTop: "20px" }}
        >
          Neues Rezept
        </Button>
      </Container>
    </div>
  );
};
