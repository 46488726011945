import React, { useState } from "react";
import { useAuth } from "../../../../hooks/auth";
import { updatemindsetHomework, updatemindsetStep } from "../../../../hooks/users";
import { CoachingStep } from "../../../../models/coaching-steps";
import { MindsetHomework } from "../../../../models/performance-user";
import { showErrorToast, showSuccessToast } from "../../../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../../../utils/i18n";
import { PageStepper } from "../PageStepper";
import { MindsetPage1 } from "./Pages/MindsetPage1";
import { MindsetPage2 } from "./Pages/MindsetPage2";
import { MindsetPage3 } from "./Pages/MindsetPage3";
import { MindsetPage4 } from "./Pages/MindsetPage4";
import { MindsetPage5 } from "./Pages/MindsetPage5";
import { MindsetPage6 } from "./Pages/MindsetPage6";
import { MindsetPage7 } from "./Pages/MindsetPage7";
import { MindsetPageHomework } from "./Pages/MindsetPageHomework";

export const MindthingStep: React.FC = () => {
  const { user } = useAuth();
  const [mindsetHomework, setmindsetHomework] = useState<MindsetHomework>(
    user?.mindsetHomework || {
      motivation: "",
      goals: "",
      habits: "",
      challenges: "",
    },
  );

  if (!user) {
    return null;
  }

  const activeStep = user.mindsetStep;

  const handleSubmit = async () => {
    try {
      await updatemindsetHomework(user.uid, mindsetHomework);
      showSuccessToast(
        "Vielen Dank für Deine Hausaufgabe! Schau morgen wieder vorbei, dann geht es weiter.",
      );
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    }
  };

  const setActiveStep = (step: number) => updatemindsetStep(user.uid, step);

  return (
    <PageStepper
      activeStep={activeStep}
      onChangeStep={setActiveStep}
      onSubmit={handleSubmit}
      submitDisabled={
        user.coachingStep !== CoachingStep.MIND_THING ||
        (user.mindsetHomework.challenges === mindsetHomework.challenges &&
          user.mindsetHomework.goals === mindsetHomework.goals &&
          user.mindsetHomework.habits === mindsetHomework.habits &&
          user.mindsetHomework.motivation === mindsetHomework.motivation)
      }
      steps={[
        <MindsetPage1 />,
        <MindsetPage2 />,
        <MindsetPage3 />,
        <MindsetPage4 />,
        <MindsetPage5 />,
        <MindsetPage6 />,
        <MindsetPage7 />,
        <MindsetPageHomework mindsetHomework={mindsetHomework} onChange={setmindsetHomework} />,
      ]}
    />
  );
};
