import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { Template, TemplateCategory } from "../models/template";

const NO_TEMPLATE: Template = {
  category: TemplateCategory.NONE,
  id: "none",
  name: "Freitext",
  text: "",
};

export const useTemplates = () => {
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(
    () =>
      firebase
        .firestore()
        .collection("templates")
        .onSnapshot((docs) => {
          setTemplates([
            NO_TEMPLATE,
            ...docs.docs
              .map((doc) => {
                const data = doc.data();
                return {
                  id: doc.id,
                  ...data,
                } as Template;
              })
              .sort((a, b) => a.name.localeCompare(b.name)),
          ]);
        }),
    [],
  );

  return templates;
};
