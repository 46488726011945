import { Typography } from "@material-ui/core";
import React from "react";
import motivationImage from "../../../../../assets/motivation.svg";
import { PageLayout } from "../../PageLayout";

export const MindsetPage3: React.FC = () => {
  return (
    <PageLayout title="Motivation" image={motivationImage}>
      <Typography>
        <strong>WOFÜR machen wir es?</strong>
      </Typography>
      <Typography paragraph>
        Das ist die wichtigste Frage, die Du Dir initial beantworten musst.
      </Typography>
      <Typography paragraph>
        Die Antwort darauf sollte immer eine sein, die aus Dir kommt und in Dir wurzelt. Deine{" "}
        <strong>interne Motivation</strong> ist verbunden mit Selbstrespekt und Selbstverantwortung
        und in Deinen Werten verankert.
      </Typography>
      <Typography>
        Eine <strong>externe Motivation</strong> - sprich: wie sehen andere Dich - kann natürlich
        auch eine Motivation sein. Ich nenne diese Form „Ego-Motivation“ – ich will anderen zeigen
        wie gut ich bin und was ich drauf habe. Diese ist allerdings meist nicht nachhaltig und
        besitzt nicht die Stärke einer internen Motivation.{" "}
      </Typography>
    </PageLayout>
  );
};
