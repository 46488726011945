import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { Recipe } from "../models/recipe";

export const useRecipes = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  useEffect(
    () =>
      firebase
        .firestore()
        .collection("recipes")
        .onSnapshot((docs) => {
          setRecipes(
            docs.docs
              .map((doc) => {
                const data = doc.data();
                return {
                  id: doc.id,
                  ...data,
                } as Recipe;
              })
              .sort((a, b) => a.name.localeCompare(b.name)),
          );
        }),
    [],
  );

  return recipes;
};
