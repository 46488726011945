import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOrInfoIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import { toast, ToastOptions } from "react-toastify";

const renderToastContent = (message: string, icon: any) => {
  const style = {
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      paddingRight: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <div style={style.wrapper}>
      {" "}
      <div style={style.icon}>{icon}</div> <div>{message}</div>{" "}
    </div>
  );
};

export const showInfoToast = (message: string, options: ToastOptions = {}) => {
  toast.info(
    renderToastContent(message, <ErrorOrInfoIcon style={{ color: "#2196f3" }} />),
    options,
  );
};

export const showSuccessToast = (message: string, options: ToastOptions = {}) => {
  toast.success(
    renderToastContent(message, <CheckCircleIcon style={{ color: "#4caf50" }} />),
    options,
  );
};

export const showWarningToast = (message: string, options: ToastOptions = {}) => {
  toast.warn(renderToastContent(message, <WarningIcon style={{ color: "#ff9800" }} />), options);
};

export const showErrorToast = (message: string, options: ToastOptions = {}) => {
  toast.error(
    renderToastContent(message, <ErrorOrInfoIcon style={{ color: "#f44336" }} />),
    options,
  );
};
