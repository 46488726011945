export const paths = {
  ROOT: "/",
  PROFILE: "/profil",
  COACHING: "/coaching",
  COACHING_USER_DETAIL: "/coaching/:uid",
  TEMPLATES: "/vorlagen",
  RECIPES: "/rezepte",
  SIGNUP: "/anmeldung",
  FORGOT_PASSWORD: "/passwort-vergessen",
};

export const buildUserDetailPath = (uid: string) => `/coaching/${uid}`;
