import {
  AppBar as MuiAppBar,
  Avatar,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { navigate, RouteComponentProps } from "@reach/router";
import React from "react";
import appLogo from "../../assets/logo.png";
import { logout } from "../../firebase";
import { useAuth } from "../../hooks/auth";
import { useIsCoach } from "../../hooks/is-coach";
import { paths } from "../../routes/routes";
import styles from "./AppBar.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: "white",
      border: 0,
      color: theme.palette.secondary.main,
      boxShadow: "none",
      padding: "10px 0",
      marginBottom: 32,
    },
    userIcon: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      // border: `2px solid ${theme.palette.primary.main}`,
    },
  }),
);

export const AppBar: React.FC<RouteComponentProps> = () => {
  const { user } = useAuth();
  const isCoach = useIsCoach();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const onMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onNavigate = (path: string) => () => {
    setAnchorEl(null);
    return navigate(path);
  };

  return (
    <MuiAppBar className={classes.appBar} position="static">
      <Toolbar>
        <div className={styles.logoWrapper}>
          <img
            className={styles.logo}
            alt=" Stockdale|coaching"
            src={appLogo}
            onClick={onNavigate(paths.ROOT)}
          />
        </div>
        <div>
          <IconButton
            aria-label="User Account"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={onMenu}
            color="inherit"
          >
            {user?.photoURL ? (
              <Avatar
                className={classes.userIcon}
                src={user?.photoURL}
                title={user?.displayName || ""}
              />
            ) : (
              <Avatar className={classes.userIcon} title={user?.displayName || ""} />
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={onClose}
          >
            {isCoach && <MenuItem onClick={onNavigate(paths.COACHING)}>Coaching Area</MenuItem>}
            {isCoach && (
              <MenuItem onClick={onNavigate(paths.TEMPLATES)}>Vorlagen verwalten</MenuItem>
            )}
            {isCoach && <MenuItem onClick={onNavigate(paths.RECIPES)}>Rezepte verwalten</MenuItem>}
            {isCoach && <Divider />}
            <MenuItem onClick={onNavigate(paths.PROFILE)}>Mein Profil</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};
