import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth";
import { updateDisplayName, updateMotivation } from "../../hooks/users";
import { MotivationType } from "../../models/motivation";
import { getFirstNameOrNothing } from "../../utils/i18n";
import { MotivationCard, MOTIVATIONS } from "../MotivationCard/MotivationCard";
import styles from "./Onboarding.module.css";

export type Props = {
  onFinish: () => void;
  onSkip: () => void;
};

export const Onboarding: React.FC<Props> = ({ onFinish, onSkip }) => {
  const [selectedMotivation, setSelectedMotivation] = useState<MotivationType[]>([]);
  const { user } = useAuth();
  const [name, setName] = useState("");
  const nameOrNothing = getFirstNameOrNothing(user);

  useEffect(() => {
    if (user) {
      setSelectedMotivation(user.motivation);
      setName(user.displayName || user.customName || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (set: typeof setName) => ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    set(value);
  };

  const handleSelectType = (type: MotivationType) => () => {
    if (selectedMotivation.length > 0) {
      setSelectedMotivation([type]);
      return;
    }
    if (selectedMotivation.includes(type)) {
      setSelectedMotivation(selectedMotivation.filter((motivation) => motivation !== type));
    } else {
      setSelectedMotivation([...selectedMotivation, type]);
    }
  };

  const handleFinish = () => {
    if (selectedMotivation) {
      updateMotivation(selectedMotivation, user?.uid);
    }
    if (name) {
      updateDisplayName(name, user?.uid);
    }

    onFinish();
  };

  if (!user) {
    return null;
  }

  return (
    <div className={styles.root}>
      <h1>Hallo{nameOrNothing}!</h1>
      <p className={styles.intro}>
        Herzlich willkommen bei Deinem ganz persönlichen Coaching Programm. Bevor Du startest,
        verrate mir bitte noch Dein <strong>Ziel</strong>, damit ich mich optimal auf Dich
        vorbereiten kann. Du kannst natürlich mehrere Ziele haben, aber ich bitte Dich erst einmal
        eins zu priorisieren – so dass wir gemeinsam einen <strong>Fokus setzen</strong> können. Du
        kannst es hier ganz einfach mit einem Klick markieren.
      </p>

      <div className={styles.motivations}>
        {MOTIVATIONS.map(({ title, type }) => (
          <MotivationCard
            title={title}
            onClick={handleSelectType(type)}
            selected={selectedMotivation.includes(type)}
          />
        ))}
      </div>

      {!Boolean(user?.displayName) ? (
        <>
          <p className={styles.intro}>
            Wenn Du magst, kannst Du hier auch noch Deinen Namen eingeben, damit ich weiß, wie Du
            heißt.
          </p>
          <div className={styles.nameContainer}>
            <TextField
              variant="outlined"
              margin="normal"
              label="Dein Name"
              name="name"
              type="text"
              value={name}
              onChange={onChange(setName)}
            />
          </div>
        </>
      ) : null}

      <div className={styles.buttons}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFinish}
          disabled={selectedMotivation.length === 0}
        >
          Los geht's
        </Button>
        <Button onClick={onSkip}>Später ausfüllen</Button>
      </div>
    </div>
  );
};
