import { Grid, Typography } from "@material-ui/core";
import React from "react";
import metabolismCircleIcon from "../../../../../assets/metabolism_circle.svg";
import { PageLayout } from "../../PageLayout";

export const MetabolismPage7: React.FC = () => {
  return (
    <PageLayout title="Fazit">
      <Typography>
        Die Balance und Interaktion der 3 Haushalte ist die Basis, um die{" "}
        <strong>Nahrung für Deine Ziele</strong> zu definieren.
      </Typography>
      <Typography paragraph>
        Und wie bereits im Eingangstext gesagt, kann das nur im Dialog zwischen Dir und mir
        geschehen. Daher wird es das <strong>Kernthema unserer Live Session</strong> sein.
      </Typography>
      <Grid container justify="center">
        <Grid item>
          <img src={metabolismCircleIcon} alt="" />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
