import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import breakfast from "../../../../../assets/breakfast.svg";
import foodImage from "../../../../../assets/food.svg";
import { PageLayout } from "../../PageLayout";

const useStyles = makeStyles(() =>
  createStyles({
    mobileScrollContainer: {
      marginTop: 40,
      height: "100%",
      overflowX: "auto",
    },
  }),
);

export const RecipesPage5: React.FC = () => {
  const classes = useStyles();
  return (
    <PageLayout title="Frühstück | Smoothie" image={foodImage}>
      <Typography paragraph>
        Besonders im Schlaf arbeitet Dein Körper – um zu entgiften, zu regenerieren und zu
        reparieren. Morgens hat er dementsprechend einen hohen Bedarf an{" "}
        <strong>Vitaminen und Mineralien</strong>. Daher trinke ich zum Frühstück als Erstes einen
        Smoothie, um „alles wieder aufzufüllen“.
      </Typography>
      <Typography paragraph>
        Natürlich ist das nicht jeden Tag notwendig. Aber es ist ein sehr guter Ersatz bzw. eine
        sehr gute Ergänzung – je nach Deiner Zielsetzung.
      </Typography>
      <Typography paragraph>
        In diesem Modul habe ich beispielhaft zusammengesetzt, welche Optionen Du hast - dies ist
        natürlich nur ein kleiner Ausschnitt Deiner vielfältigen Möglichkeiten.
      </Typography>
      <div className={classes.mobileScrollContainer}>
        <img src={breakfast} alt="" />
      </div>
    </PageLayout>
  );
};
