import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { format } from "date-fns";
import React, { ChangeEvent, useState } from "react";
import cookingImage from "../../../../assets/cooking.svg";
import diaryImage from "../../../../assets/diary.svg";
import shoppingListImage from "../../../../assets/shopping-list.svg";
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator";
import { PhotoGrid } from "../../../../components/PhotoGrid/PhotoGrid";
import { PhotoUpload } from "../../../../components/PhotoUpload/PhotoUpload";
import { SectionCard } from "../../../../components/SectionCard/SectionCard";
import { useAuth } from "../../../../hooks/auth";
import { useIsCoach } from "../../../../hooks/is-coach";
import { usePhotos } from "../../../../hooks/photos";
import { deleteMonitoringWeek, updateMonitoringWeek } from "../../../../hooks/users";
import { MonitoringWeek } from "../../../../models/performance-user";
import { showErrorToast } from "../../../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../../../utils/i18n";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    weekTitle: {
      width: "100%",
      color: theme.palette.primary.main,
    },
    embeddedPhotoUpload: {
      margin: "30px 0",
    },
    submitButton: {
      marginTop: 10,
    },
    moodWrapper: {
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   padding: 20,
    },
  }),
);

export const MonitoringWeekItem: React.FC<{
  week: MonitoringWeek;
  weekIndex: number;
}> = ({ week, weekIndex }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [shoppingList, setShoppingList] = useState(week.shoppingList);
  const [thoughts, setThoughts] = useState(week.thoughts);

  const isCoach = useIsCoach();

  const { photos, updatePhotos, handleDelete, loading: photosLoading } = usePhotos(
    user?.uid,
    week.id,
  );

  const handleDeleteWeek = async () => {
    if (user) {
      await deleteMonitoringWeek(user.uid, week);
    }
  };

  const handleShoppingListUpdate = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    setShoppingList(value);
  };

  const handleShoppingListSubmit = async () => {
    if (user) {
      try {
        await updateMonitoringWeek(user.uid, {
          ...week,
          shoppingList,
        });
      } catch (error) {
        console.error(error);
        showErrorToast(DEFAULT_ERROR_MESSAGE);
      }
    }
  };

  const handleThoughtsUpdate = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    setThoughts(value);
  };

  const handleThoughtsSubmit = async () => {
    if (user) {
      try {
        await updateMonitoringWeek(user.uid, {
          ...week,
          thoughts,
        });
      } catch (error) {
        console.error(error);
        showErrorToast(DEFAULT_ERROR_MESSAGE);
      }
    }
  };

  return (
    <Grid item>
      <Typography variant="h2" className={classes.weekTitle}>
        Woche {weekIndex}{" "}
        <Typography variant="caption">
          (Gestartet am {format(new Date((week.startDate as any).seconds * 1000), "dd.MM.yyyy")})
        </Typography>{" "}
        {isCoach ? (
          <IconButton onClick={handleDeleteWeek} title="Woche löschen (Nur für coaches)">
            <DeleteIcon />
          </IconButton>
        ) : null}
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SectionCard title="Deine Einkaufsliste" image={shoppingListImage}>
            <Typography paragraph>
              Plane hier, welche Nahrungsmittel Du diese Woche für Deine Mahlzeiten benötigst - und
              dann viel Spaß beim Einkaufen!
            </Typography>
            <TextField
              placeholder={`Kartoffeln   \nTomaten   \nPaprika   \nErbsen   \nÄpfel   \nIngwer   \nHafermilch`}
              variant="outlined"
              multiline
              rows={8}
              fullWidth
              onChange={handleShoppingListUpdate}
              value={shoppingList}
            />
            <Button
              onClick={handleShoppingListSubmit}
              className={classes.submitButton}
              size="small"
              variant="outlined"
              color="primary"
              // fullWidth
              disabled={shoppingList === week.shoppingList}
            >
              Speichern
            </Button>
          </SectionCard>
        </Grid>
        <Grid item xs={12}>
          <SectionCard title="Deine Mahlzeiten" image={cookingImage}>
            <Typography paragraph>
              Wie im Food Audit lädst Du hier die Fotos deiner Mahlzeiten hoch. Ich begleite Dich
              dabei jeden Tag und gebe Dir Feedback.
            </Typography>
            <div className={classes.embeddedPhotoUpload}>
              <PhotoUpload onUpload={updatePhotos} weekId={week.id} />
              {photosLoading ? (
                <LoadingIndicator />
              ) : user ? (
                <PhotoGrid user={user} photos={photos} onDelete={handleDelete} weekId={week.id} />
              ) : null}
            </div>
          </SectionCard>
        </Grid>
        <Grid item xs={12}>
          <SectionCard title="Dein Tagebuch" image={diaryImage}>
            <Typography paragraph>
              Hier kannst Du <strong>„frei Schnauze“</strong> reinschreiben, wie Du Dich mit den
              Veränderungen fühlst. Was läuft gut? Was läuft vielleicht nicht so gut? Gibt es
              Herausforderungen, bei denen Du weitere <strong>Unterstützung von mir</strong>{" "}
              brauchst?
            </Typography>
            <TextField
              placeholder={`Tag 1: Heute habe ich ...     \nTag 2: Heute hat sich verändert ...`}
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              onChange={handleThoughtsUpdate}
              value={thoughts}
            />
            <Button
              onClick={handleThoughtsSubmit}
              className={classes.submitButton}
              size="small"
              variant="outlined"
              color="primary"
              // fullWidth
              disabled={thoughts === week.thoughts}
            >
              Speichern
            </Button>
          </SectionCard>
        </Grid>
      </Grid>
    </Grid>
  );
};
