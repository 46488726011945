export enum TemplateCategory {
  NONE = "NONE",
  FOOD_AUDIT = "FOOD_AUDIT",
  HANDLUNGSEMPFEHLUNG = "HANDLUNGSEMPFEHLUNG",
  OUTRO = "OUTRO",
}

export type Template = {
  id: string;
  category: TemplateCategory;
  name: string;
  text: string;
};
