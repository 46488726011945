import React, { useState } from "react";
import { useAuth } from "../../../../hooks/auth";
import { updateMetabolismHomework, updateMetabolismStep } from "../../../../hooks/users";
import { CoachingStep } from "../../../../models/coaching-steps";
import { showErrorToast, showSuccessToast } from "../../../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../../../utils/i18n";
import { PageStepper } from "../PageStepper";
import { MetabolismPage1 } from "./Pages/MetabolismPage1";
import { MetabolismPage2 } from "./Pages/MetabolismPage2";
import { MetabolismPage3 } from "./Pages/MetabolismPage3";
import { MetabolismPage4 } from "./Pages/MetabolismPage4";
import { MetabolismPage5 } from "./Pages/MetabolismPage5";
import { MetabolismPage6 } from "./Pages/MetabolismPage6";
import { MetabolismPage7 } from "./Pages/MetabolismPage7";
import { MetabolismPageHomework } from "./Pages/MetabolismPageHomework";

export const MetabolismStep: React.FC = () => {
  const { user } = useAuth();
  const [metabolismHomework, setMetabolismHomework] = useState<string>(
    user?.metabolismHomework || "",
  );

  if (!user) {
    return null;
  }

  const activeStep = user.metabolismStep;

  const handleSubmit = async () => {
    try {
      await updateMetabolismHomework(user.uid, metabolismHomework);
      showSuccessToast(
        "Vielen Dank für Deine Hausaufgabe! Schau morgen wieder vorbei, dann geht es weiter.",
      );
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    }
  };

  const setActiveStep = (step: number) => updateMetabolismStep(user.uid, step);

  return (
    <PageStepper
      activeStep={activeStep}
      onChangeStep={setActiveStep}
      onSubmit={handleSubmit}
      submitDisabled={
        user.metabolismHomework === metabolismHomework ||
        user.coachingStep !== CoachingStep.METABOLISM
      }
      steps={[
        <MetabolismPage1 />,
        <MetabolismPage2 />,
        <MetabolismPage3 />,
        <MetabolismPage4 />,
        <MetabolismPage5 />,
        <MetabolismPage6 />,
        <MetabolismPage7 />,
        <MetabolismPageHomework
          metabolismHomework={metabolismHomework}
          onChange={setMetabolismHomework}
        />,
      ]}
    />
  );
};
