import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Checklist } from "../Checklist/Checklist";

type Props = {
  title: string;
  image: string;
  items?: string[];
};
export const SectionCard: React.FC<Props> = ({ title, image, items, children }) => {
  return (
    <Card>
      <CardContent>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          style={{ marginBottom: "10px" }}
        >
          <Typography variant="h6">{title}</Typography>
          <img src={image} alt="" height={40}></img>
        </Grid>
        {items && <Checklist items={items} />}
        {children}
      </CardContent>
    </Card>
  );
};
