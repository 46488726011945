import { Typography } from "@material-ui/core";
import React from "react";
import metabolismImage from "../../../../../assets/metabolism.svg";
import { PageLayout } from "../../PageLayout";

export const MetabolismPage1: React.FC = () => {
  return (
    <PageLayout title="Stoffwechsel" image={metabolismImage}>
      <Typography paragraph>
        Das Thema „Stoffwechsel“ ist sehr umfangreich und komplex – damit lassen sich Bücher und
        Bibliotheken füllen. Ich habe versucht, es <strong>so einfach wie möglich</strong>{" "}
        zusammenzufassen, um es dementsprechend für Dich praktisch anwendbar zu machen.
      </Typography>
      <Typography paragraph>
        Die 3 Haushalte – über die Du gleich lesen wirst – sind keine „offiziellen Terminologien“.
        Ich habe diese Einteilung für ein besseres Verständnis selbst so definiert.
      </Typography>
      <Typography paragraph>
        <strong>Wichtig:</strong> Um die Ernährung für Deine Ziele zu definieren, wird Stoffwechsel
        das Hauptthema in unserer Live Session sein. Denn <strong>nur im Dialog miteinander</strong>{" "}
        können wir die individuellen Lösungen für dich finden!
      </Typography>
    </PageLayout>
  );
};
