import React, { useState } from "react";
import { useAuth } from "../../../../hooks/auth";
import {
  addMonitoringWeek,
  updateMonitoringHow,
  updateMonitoringStep,
  updateMonitoringWhat,
} from "../../../../hooks/users";
import { showErrorToast, showSuccessToast } from "../../../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../../../utils/i18n";
import { PageStepper } from "../PageStepper";
import { MonitoringWeekItem } from "./MonitoringWeekItem";
import { MonitoringPage1 } from "./Pages/MonitoringPage1";
import { MonitoringPage2 } from "./Pages/MonitoringPage2";
import { MonitoringPageOutro } from "./Pages/MonitoringPageOutro";

const MAX_WEEKS = 4;

export const MonitoringStep: React.FC = () => {
  const { user } = useAuth();
  const [how, setHow] = useState<string>(user?.monitoringHow || "");
  const [what, setWhat] = useState<string>(user?.monitoringWhat || "");

  if (!user) {
    return null;
  }

  const activeStep = user.monitoringStep;

  const setActiveStep = (step: number) => updateMonitoringStep(user.uid, step);

  const handleChange = async (monitoringHow: string, monitoringWhat: string) => {
    setHow(monitoringHow);
    setWhat(monitoringWhat);
  };

  const handleSubmit = async () => {
    // Handle MAX_WEEKS over
    if (user.monitoringWeeks.length >= MAX_WEEKS) {
      // TODO Add modal or something
      return;
    }

    // Handle new week
    if (user.monitoringWeeks.length > 0) {
      await addMonitoringWeek(user.uid);
      await setActiveStep(activeStep + 1);
      return;
    }

    // Handle how/what submit
    try {
      await updateMonitoringHow(user.uid, how);
      await updateMonitoringWhat(user.uid, what);
      await addMonitoringWeek(user.uid);
      await setActiveStep(activeStep + 1);
      showSuccessToast("Vielen Dank! Starten wir in die erste Woche.");
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    }
  };

  const steps = [
    <MonitoringPage1 />,
    <MonitoringPage2
      how={how}
      what={what}
      onChange={handleChange}
      disabled={user.monitoringWeeks.length > 0}
    />,
    ...user.monitoringWeeks.map((week, index) => (
      <MonitoringWeekItem key={week.id} week={week} weekIndex={index + 1} />
    )),
  ];

  // Add outro page if enabled
  if (user.outroPageEnabled) {
    steps.push(<MonitoringPageOutro />);
  }

  const submitButtonText =
    user?.monitoringWeeks.length > 0 && activeStep === steps.length - 1 ? "Neue Woche" : undefined;

  const submitDisabled = user.outroPageEnabled || user.monitoringWeeks.length >= MAX_WEEKS;

  return (
    <PageStepper
      activeStep={activeStep}
      onChangeStep={setActiveStep}
      onSubmit={handleSubmit}
      submitButtonText={submitButtonText}
      submitDisabled={submitDisabled}
      steps={steps}
    />
  );
};
