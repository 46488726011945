import { Container, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { UsersTable } from "../../components/UsersTable/UsersTable";
import { useUsers } from "../../hooks/users";
import styles from "./CoachingView.module.css";

export const CoachingView: React.FC<RouteComponentProps> = () => {
  const users = useUsers();

  return (
    <div className={styles.root}>
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Alle Benutzer
        </Typography>
        <UsersTable users={users} />
      </Container>
    </div>
  );
};
