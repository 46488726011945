import {
  Button,
  Card,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { lazy, Suspense, useEffect, useState } from "react";
import circleIcons from "../../../../assets/circle-icons.svg";
import { Checklist } from "../../../../components/Checklist/Checklist";
import { LoadingIndicator } from "../../../../components/LoadingIndicator/LoadingIndicator";
import { PhotoGrid } from "../../../../components/PhotoGrid/PhotoGrid";
import { PhotoUpload } from "../../../../components/PhotoUpload/PhotoUpload";
import { useAuth } from "../../../../hooks/auth";
import { usePhotos } from "../../../../hooks/photos";
import { CoachingStep } from "../../../../models/coaching-steps";
import styles from "./FoodAuditStep.module.css";

const AnalysisPdfDownload = lazy(
  () => import("../../../../components/AnalysisPdf/AnalysisPdfDownload"),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcome: {
      // color: theme.palette.primary.main,
      color: theme.palette.primary.main,
      opacity: 0.6,
      marginBottom: 20,
    },
    card: {
      padding: 20,
      marginBottom: 20,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    caption: {
      opacity: 0.8,
      marginLeft: 10,
    },
    collapse: {
      "& span": {
        color: theme.palette.primary.main,
      },
    },
    paymentButton: {
      marginTop: 20,
      width: "100%",
    },
    upsellingCard: {
      marginTop: 30,
      padding: "20px 20px 20px 20px ",
    },
    analysisCard: {
      padding: "20px 20px 20px 20px ",
    },
    analysisTitle: {
      textAlign: "center",
      marginBottom: 20,
    },
    upsellingIcon: {
      maxWidth: 200,
      margin: 10,
    },
    upsellImageWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 20,
    },
    showFoodAuditButton: {
      display: "flex",
      justifyContent: "center",
      padding: 20,
    },
  }),
);

export const FoodAuditHelpCard: React.FC<{
  expanded: boolean;
  onExpand: () => void;
}> = ({ expanded, onExpand }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.card}>
      <div className={styles.cardHeader}>
        <Typography variant="h5">Food Audit - unsere Vorgehensweise</Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={onExpand}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
        <Typography paragraph>
          Im Food Audit verschaffe ich mir einen Überblick über Deine Ernährungsgewohnheiten.
        </Typography>
        <Typography color="primary">Und so machen wir es:</Typography>
        <Checklist
          items={[
            "Lade über 7 Tage (möglichst einander folgend) Fotos Deiner Hauptmahlzeiten, Snacks, Getränke und Nahrungsergänzungsmittel hoch - ganz einfach über Dein Smartphone oder Laptop.",
            "Ernähre Dich in dieser Zeit möglichst so, wie Du es immer tust - je mehr Informationen ich von Dir bekomme, desto besser kann ich Dich unterstützen.",
            "Am Ende der 7 Tage steht Dir hier Deine „Analyse und Handlungsempfehlung“ als PDF zur Verfügung, die Du herunterladen kannst.  Und dann entscheidest Du, ob wir gemeinsam die nächsten Schritte angehen.",
          ]}
        />
        <Typography color="primary">Ganz wichtig:</Typography>
        <Typography paragraph>
          Nichts an Deiner Ernährung ist „richtig oder falsch“. Ich schaue mir lediglich an, was Du
          an Deiner Ernährung ändern könntest, um Deine Ziele zu erreichen.
        </Typography>
        <Typography paragraph>
          Ich begleite Dich über die Tage, werde Dir immer wieder Feedback geben und freue mich
          schon auf Deine Fotos.
        </Typography>
      </Collapse>
    </Paper>
  );
};

export const FoodAuditStep: React.FC = () => {
  const { user } = useAuth();
  const { photos, updatePhotos, handleDelete, loading: photosLoading } = usePhotos(user?.uid);

  const [foodAuditVisible, setFoodAuditVisible] = useState(true);

  const classes = useStyles();

  const [introExpanded, setIntroExpanded] = useState(false);

  useEffect(() => {
    if (!photosLoading && photos.length < 3) {
      setIntroExpanded(true);
    }
  }, [photosLoading, photos]);

  useEffect(() => {
    if (user?.foodAuditAnalysisEnabled) {
      setFoodAuditVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpandClick = () => {
    setIntroExpanded(!introExpanded);
  };

  const handleShowFoodAudit = () => {
    setFoodAuditVisible(true);
  };

  if (!user) {
    return null;
  }

  const foodAuditViews = (
    <>
      {user?.foodAuditAnalysisEnabled ? null : (
        <>
          <FoodAuditHelpCard expanded={introExpanded} onExpand={handleExpandClick} />
          <Paper elevation={2} className={classes.card}>
            <PhotoUpload onUpload={updatePhotos} />
          </Paper>
        </>
      )}
      {photosLoading ? (
        <LoadingIndicator />
      ) : (
        <PhotoGrid user={user} photos={photos} onDelete={handleDelete} />
      )}
    </>
  );

  const upsellingViews = (
    <>
      <Card className={classes.upsellingCard}>
        <Typography variant="h4" className={classes.analysisTitle}>
          In die nächste Phase wechseln
        </Typography>
        <div className={classes.upsellImageWrapper}>
          <img src={circleIcons} className={classes.upsellingIcon} alt="" />
        </div>
        <Typography paragraph>
          Wenn Dir unser Austausch Freude bereitet hat und Du meine Handlungsempfehlungen umsetzen
          möchtest, würde es mich sehr freuen, mit Dir weiter zusammenzuarbeiten.
        </Typography>
        <Typography>
          Du investierst einmal € 95 und dafür steht Dir mein gesamtes Online Programm zur
          Verfügung.
        </Typography>
        <Typography paragraph>
          Sobald Deine Überweisung gutgeschrieben wurde, starten wir direkt durch.
        </Typography>
        <Typography paragraph>
          Solltest Du dazu noch Fragen habe, dann schreibe mir unter{" "}
          <a href="mailto:greg@stockdale-coaching.com" target="_blank" rel="noreferrer noopener">
            greg@stockdale-coaching.com
          </a>{" "}
          oder rufe einfach unter{" "}
          <a href="tel:+491738821377" target="_blank" rel="noreferrer noopener">
            0173-8821377
          </a>{" "}
          durch.
        </Typography>
        <Checklist
          items={[
            "Kontoinhaber: Greg S. Stockdale",
            "IBAN: DE59 3007 0024 0537 8682 60",
            "BIC: DEUTDEDBDUE",
            "Betrag: € 95.00",
            "Verwendungszweck: [DEIN NAME oder DEINE E-MAIL ADRESSE]",
          ]}
        />
      </Card>
    </>
  );

  const analysisViews = (
    <Card className={classes.analysisCard}>
      <Typography variant="h5" className={classes.analysisTitle}>
        Analyse und Handlungsempfehlung
      </Typography>
      <Typography paragraph>
        Deine persönliche Analyse und Handlungsempfehlung ist nun fertig! Hier kannst Du sie
        herunterladen.
      </Typography>
      <Typography paragraph>
        In ihr findest Du eine Einschätzung von meiner Seite und eine Zusammenfassung, wie wir
        gemeinsam Deine Ziele in Angriff nehmen können. Viel Spaß beim Lesen!
      </Typography>
      <Suspense fallback={null}>
        <AnalysisPdfDownload user={user} />
      </Suspense>
    </Card>
  );

  return (
    <>
      {user.foodAuditAnalysisEnabled ? analysisViews : null}
      {user.foodAuditAnalysisEnabled && user.coachingStep === CoachingStep.FOOD_AUDIT
        ? upsellingViews
        : null}
      {foodAuditVisible ? (
        foodAuditViews
      ) : (
        <div className={classes.showFoodAuditButton}>
          <Button onClick={handleShowFoodAudit} variant="text">
            Food Audit anzeigen
          </Button>
        </div>
      )}
    </>
  );
};
