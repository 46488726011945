import { Avatar, Card, CardContent, CardHeader, IconButton, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useState } from "react";
import Greg from "../../assets/greg-avatar.svg";
import { Message } from "../../models/performance-user";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/i18n";

type Props = {
  message: Message;
  className?: string;
  onDelete?: () => Promise<void>;
};

export const MessageCard: React.FC<Props> = ({ message, onDelete, className }) => {
  const [pending, setPending] = useState(false);

  const handleDelete = async () => {
    if (!onDelete) {
      console.error("Missing onDelete in MessageCard");
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      return;
    }

    try {
      setPending(true);
      await onDelete();
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      setPending(false);
    }
  };

  return (
    <Card className={className}>
      <CardHeader
        avatar={<Avatar src={Greg} />}
        title="Nachricht von Greg"
        subheader={message.dateCreated.toLocaleString("de-de", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
        action={
          onDelete && (
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
              disabled={pending}
              title="Nachricht löschen"
            >
              <Delete />
            </IconButton>
          )
        }
      />
      <CardContent>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {message.text}
        </Typography>
      </CardContent>
    </Card>
  );
};
