import { navigate, RouteComponentProps } from "@reach/router";
import React from "react";
import { Onboarding } from "../../components/Onboarding/Onboarding";
import { useAuth } from "../../hooks/auth";
import { removeUserFlag } from "../../hooks/users";
import { UserFlag } from "../../models/flags";
import { paths } from "../../routes/routes";
import styles from "./ProfileView.module.css";

export const ProfileView: React.FC<RouteComponentProps> = () => {
  const { user } = useAuth();

  const handleOnboardingFinish = () => {
    removeUserFlag(UserFlag.ONBOARDING, user?.uid);
    return navigate(paths.ROOT);
  };

  const handleOnboardingSkip = () => {
    removeUserFlag(UserFlag.ONBOARDING, user?.uid);
    return navigate(paths.ROOT);
  };

  return (
    <div className={styles.root}>
      <Onboarding onFinish={handleOnboardingFinish} onSkip={handleOnboardingSkip} />
    </div>
  );
};
