import { Grid } from "@material-ui/core";
import React from "react";
import energyImage from "../../../../../assets/energy.svg";
import { BibButton } from "../../../../../components/BibButton/BibButton";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

// const useStyles = makeStyles(() =>
//   createStyles({
//     subtitle: {
//       margin: "30px 0 0px 0",
//     },
//   })
// );

export const MetabolismPage4: React.FC = () => {
  // const classes = useStyles();
  return (
    <PageLayout title="Der Energie-Haushalt" image={energyImage}>
      <Grid container justify="center">
        <Checklist
          items={[
            "Wir ziehen unsere Energie aus Kohlenhydraten, Fetten und Proteinen.",
            "Die Aufnahme, Verarbeitung und Freisetzung der 3 Energielieferanten durch den Körper bzw. den Stoffwechsel ist grundverschieden.",
            "Keine dieser Energiequellen ist an sich „besser oder schlechter“ - es sind also grundsätzlich keine Restriktionen oder Verbote notwendig.",
            "Es ist nur wichtig, die Verhältnismäßigkeit der Mengen in Einklang mit den täglichen Aktivitäten zu bringen.",
          ]}
        />
        <BibButton tagSlug="energie-haushalt" />
      </Grid>
    </PageLayout>
  );
};
