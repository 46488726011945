import {
  CircularProgress,
  createStyles,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Delete, PlayArrow, Star, StarOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { v4 } from "uuid";
import { useIsCoach } from "../../hooks/is-coach";
import { DailyMessage, PerformanceUser } from "../../models/performance-user";
import { Photo } from "../../models/photo";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/i18n";
import { MessageCard } from "../MessageCard/MessageCard";
import { MessageInput } from "../MessageInput/MessageInput";
import { PhotoModal } from "../PhotoModal/PhotoModal";
import styles from "./PhotoGrid.module.css";

export enum PhotoGridVariant {
  USER_VIEW,
  COACHING_VIEW,
}

type Props = {
  user: PerformanceUser;
  photos: Photo[];
  onDelete?: (photo: Photo) => Promise<void>;
  onLike?: (photo: Photo) => Promise<void>;
  onAddDailyMessage?: (message: DailyMessage) => Promise<void>;
  onDeleteDailyMessage?: (message: DailyMessage) => Promise<void>;
  variant?: PhotoGridVariant;
  weekId?: string;
};

export const getYYYYMMDD = (date: Date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      margin: "10px 0",
    },
  }),
);

export const PhotoGrid: React.FC<Props> = ({
  user,
  photos,
  onDelete,
  onLike,
  onAddDailyMessage,
  onDeleteDailyMessage,
  variant = PhotoGridVariant.USER_VIEW,
  weekId,
}) => {
  const isCoach = useIsCoach();
  const [openPhoto, setOpenPhoto] = useState<Photo | null>(null);
  const [pending, setPending] = useState<string | null>(null);
  const classes = useStyles();

  const photosByDay = Array.from(
    photos.reduce((acc, photo) => {
      const day = getYYYYMMDD(photo.dateCreated);

      acc.set(day, (acc.get(day) || []).concat(photo));
      return acc;
    }, new Map<string, Photo[]>()),
  );

  const messagesByDay = user.dailyMessages.reduce((acc, message) => {
    if (weekId && weekId !== message.weekId) {
      return acc;
    }

    const day = getYYYYMMDD(message.day);

    acc.set(day, (acc.get(day) || []).concat(message));
    return acc;
  }, new Map<string, DailyMessage[]>());

  const handleDeletePhoto = (photo: Photo) => async () => {
    if (!onDelete) {
      console.error("Missing onDelete in PhotoGrid");
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      return;
    }

    try {
      setPending(photo.url);
      await onDelete(photo);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      setPending(null);
    }
  };

  const handleLikePhoto = (photo: Photo) => async () => {
    if (!onLike) {
      console.error("Missing onLike in PhotoGrid");
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      return;
    }

    try {
      setPending(photo.url);
      await onLike(photo);
      setPending(null);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      setPending(null);
    }
  };

  const handleAddMessage = (day: Date) => async (text: string) => {
    if (!onAddDailyMessage) {
      console.error("Missing onAddMessage in PhotoGrid");
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      return;
    }

    try {
      const message: DailyMessage = {
        id: v4(),
        text,
        dateCreated: new Date(),
        day,
      };
      if (weekId) {
        message.weekId = weekId;
      }

      await onAddDailyMessage(message);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(null);
    }
  };

  const handleDeleteMessage = (message: DailyMessage) => async () => {
    if (!onDeleteDailyMessage) {
      console.error("Missing onDeleteMessage in PhotoGrid");
      showErrorToast(DEFAULT_ERROR_MESSAGE);
      return;
    }

    try {
      await onDeleteDailyMessage(message);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(null);
    }
  };

  const onOpenPhoto = (photo: Photo) => () => setOpenPhoto(photo);

  const onClosePhoto = () => setOpenPhoto(null);

  return (
    <>
      {photosByDay.map(([day, photos]) => (
        <div key={day} className={styles.day}>
          <Typography variant={"h5"} className={classes.date}>
            {new Date(day).toLocaleString("de-DE", {
              weekday: "long",
              day: "numeric",
              month: "long",
            })}
          </Typography>
          {messagesByDay
            .get(day)
            ?.reverse()
            .map((message) => (
              <MessageCard
                key={message.id}
                message={message}
                className={styles.messageCard}
                onDelete={
                  variant === PhotoGridVariant.COACHING_VIEW
                    ? handleDeleteMessage(message)
                    : undefined
                }
              />
            ))}
          {/* New message */}
          {isCoach && !messagesByDay.get(day) && variant === PhotoGridVariant.COACHING_VIEW && (
            <MessageInput onSubmit={handleAddMessage(new Date(day))} />
          )}
          <GridList className={styles.grid} cols={3}>
            {/* Messages */}
            {photos.map((photo, index) => (
              <GridListTile
                key={photo.url}
                cols={index % 7 === 0 || index % 7 === 6 ? 2 : 1}
                className={styles.image}
              >
                {photo.isVideo ? (
                  <div onClick={onOpenPhoto(photo)}>
                    <PlayArrow className={styles.playButton} />
                    <video src={photo.url + "#t=0.001"} className={styles.video} />
                  </div>
                ) : (
                  <img src={photo.url} alt="" onClick={onOpenPhoto(photo)} />
                )}

                {user.likedPhotos.includes(photo.fullPath) && <Star className={styles.star} />}

                <GridListTileBar
                  className={styles.actions}
                  title={
                    photo.dateCreated.toLocaleString("de-DE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }) + " Uhr"
                  }
                  subtitle={photo.dateCreated.toLocaleString("de-DE", {
                    weekday: "long",
                  })}
                  actionIcon={
                    pending === photo.url ? (
                      <CircularProgress color="inherit" className={styles.spinner} />
                    ) : variant === PhotoGridVariant.USER_VIEW ? (
                      <IconButton
                        aria-label={"Löschen"}
                        className={styles.icon}
                        onClick={handleDeletePhoto(photo)}
                        disabled={pending === photo.url}
                      >
                        <Delete />
                      </IconButton>
                    ) : (
                      !user.likedPhotos.includes(photo.fullPath) && (
                        <IconButton
                          aria-label={"Like"}
                          className={styles.icon}
                          onClick={handleLikePhoto(photo)}
                          disabled={pending === photo.url}
                        >
                          <StarOutlined />
                        </IconButton>
                      )
                    )
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      ))}

      {/* Open photo */}
      {openPhoto && <PhotoModal photo={openPhoto} onClose={onClosePhoto} />}
    </>
  );
};
