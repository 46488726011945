import { Grid, Typography } from "@material-ui/core";
import React from "react";
import PathToSuccessIcon from "../../../../../assets/graph.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

export const MindsetPage4: React.FC = () => {
  return (
    <PageLayout title="Der Veränderungsprozess" image={PathToSuccessIcon}>
      <Typography>
        Veränderung kostet emotionale und körperliche Energie und unsere Energieressourcen sind
        begrenzt. Und auch bzw. gerade Veränderung in eine positive Richtung kann sich am Anfang
        unangenehm anfühlen.
        <br />
        Also:
      </Typography>
      <Grid container justify="center">
        <Checklist
          items={[
            "Versuche nicht alles auf einmal zu verändern.",
            "Identifiziere welche Veränderungen potentiell die einfachsten für Dich sind und beginne damit.",
            "Breche zeitlich die Veränderungen in Etappen runter, damit das Erklimmen des Berges nicht unmöglich erscheint.",
            "Arbeite möglichst nicht mit absoluten Verboten und Restriktionen, da die „Verlustangst etwas nie mehr machen zu dürfen“, Dich versteifen und hemmen könnte. Besser ist es, Bestehendes Schritt für Schritt zu modifizieren.",
          ]}
        />
      </Grid>
    </PageLayout>
  );
};
