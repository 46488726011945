import { Typography } from "@material-ui/core";
import React from "react";
import gregImage from "../../../../../assets/greg.svg";
import { useAuth } from "../../../../../hooks/auth";
import { PageLayout } from "../../PageLayout";

export const MonitoringPageOutro: React.FC = () => {
  const { user } = useAuth();
  return (
    <PageLayout title="Herzlichen Glückwunsch!" image={gregImage}>
      <Typography style={{ whiteSpace: "pre-line" }}>{user?.outroPageText}</Typography>
    </PageLayout>
  );
};
