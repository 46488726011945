import { createStyles, makeStyles } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

interface Props {
  severity: "error" | "warning" | "info" | "success";
}

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      margin: "10px 0",
      border: "none",
    },
  }),
);

export const Alert: React.FC<Props> = ({ severity, children }) => {
  const classes = useStyles();

  return (
    <MuiAlert className={classes.alert} severity={severity}>
      {children}
    </MuiAlert>
  );
};
