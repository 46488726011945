import { useAuth } from "./auth";

const COACHES = [
  "80VV3krmIpgxjve9YClYqrKE1cl1", // stockdale.coaching.dev@gmail.com
  "NihTlrtmVsVxjhIwMBiCy1roDs72", // greg@stockdale-coaching.com
  "RywHECzvpuOd7oChNpC4J0Fh6IC2", // greg.s.stockdale@gmail.com
  "OHrEqmawSRXDbPr8qMCvr9mqsA72", // ben@einsen-und-nullen.de
  "Ovnw113x9lWV9zoSs3Wd4VvXvcm1", // peter@einsen-und-nullen.de
  "dbc9LtlmIngDojF7ihPazTTFsFF3", // rowwwar@googlemail.com
];

export const isCoach = (uid?: string) => uid && COACHES.includes(uid);

export const useIsCoach = () => {
  const { user } = useAuth();

  return user !== null && isCoach(user.uid);
};
