import { Typography } from "@material-ui/core";
import React from "react";
import foodImage from "../../../../../assets/food.svg";
import { useAuth } from "../../../../../hooks/auth";
import { getFirstNameOrNothing } from "../../../../../utils/i18n";
import { PageLayout } from "../../PageLayout";

export const RecipesPage1: React.FC = () => {
  const { user } = useAuth();
  const name = getFirstNameOrNothing(user);
  return (
    <PageLayout title="Nahrungsmittel & Inspirationen" image={foodImage}>
      <Typography paragraph>
        Hallo{name}, bevor Du in das Thema „Nahrungsmittel & Inspirationen“ eintauchst, ein paar
        einleitende Worte:
      </Typography>
      <Typography paragraph>
        Geschmäcker sind so vielfältig, Essgewohnheiten so unterschiedlich und die schiere Menge an
        Nahrungsmittel so umfangreich, dass es unmöglich für mich ist, die gesamte Komplexität hier
        abzufangen und darzustellen.
      </Typography>
      <Typography paragraph>
        Daher nimm die folgenden Informationen bitte als Anregung und Inspiration.
      </Typography>
      <Typography paragraph>
        <strong>
          Sie dienen als Grundlage für unser Gespräch in der Live Session, in der wir Deine
          individuelle Umsetzung planen!
        </strong>
      </Typography>
    </PageLayout>
  );
};
