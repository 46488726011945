import { Button, CardContent, Dialog, Fab, Slide, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Close } from "@material-ui/icons";
import React from "react";
import { Recipe } from "../../models/recipe";
import styles from "./RecipeModal.module.css";

type Props = {
  recipe: Recipe;
  onClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RecipeModal: React.FC<Props> = ({ recipe, onClose }) => {
  return (
    <Dialog
      open={recipe !== null}
      onClose={onClose}
      TransitionComponent={Transition}
      // Prevents page jump
      disableRestoreFocus
      maxWidth="sm"
    >
      <div className={styles.closeButtonContainer}>
        <Fab
          color="primary"
          size="small"
          aria-label="Schließen"
          className={styles.closeButton}
          onClick={onClose}
        >
          <Close />
        </Fab>
      </div>
      <img src={recipe.photo} className={styles.photo} alt="" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {recipe.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
          {recipe.text}
        </Typography>
        {recipe.link ? <Button className={styles.button} color="primary" target="_blank" rel="noreferrer noopener" href={recipe.link} variant="contained">Rezept ansehen</Button> : null}
      </CardContent>
    </Dialog>
  );
};
