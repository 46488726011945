import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import acidImage from "../../../../../assets/acid.svg";
import energyImage from "../../../../../assets/energy.svg";
import nutritionImage from "../../../../../assets/nutrition.svg";
import { SectionCard } from "../../../../../components/SectionCard/SectionCard";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: "10px",
    },
  }),
);

export const RecipesPage3: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          Und so funktioniert es:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SectionCard
          title="Energie-Haushalt"
          image={energyImage}
          items={[
            "Die Nahrungsmittel sind so kategorisiert, dass Du den Protein-, Kohlenhydrat- und Fettgehalt leicht erkennen kannst.",
            "Ich habe bewusst keine Grammangaben gemacht, da ich „Kochen mit Taschenrechner“ für nicht besonders achtsam und zielführend halte.",
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionCard
          title="Säure-Basen-Haushalt"
          image={acidImage}
          items={[
            "Sämtliche im Modul vorgeschlagenen Nahrungsmittel werden basisch oder zumindest neutral verstoffwechselt.",
            "Du kannst natürlich Deinem Wunsch entsprechend sauer verstoffwechselte Nahrungsmittel hinzufügen.",
            "Diese Vorgehensweise macht es für Dich hoffentlich einfacher, die 80/20-Relation von basisch zu sauer ungefähr abzuschätzen und einzuhalten.",
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionCard
          title="Nährwert-Haushalt"
          image={nutritionImage}
          items={[
            "Sämtliche im Modul vorgeschlagene Nahrungsmittel sind Gemüse und Obst – damit bist Du, was Vitamine und Mineralien angeht, ganz weit vorne.",
            "Weiterhin werden sämtliche im Modul vorgeschlagenen Nahrungsmittel basisch bzw. neutral verstoffwechselt – somit entziehen sie Deinem Körper keine Nährwerte.",
            "Nichtsdestotrotz gilt die in „Stoffwechsel“ ausgesprochene Empfehlung: Wir „müssen“ gewisse Vitamine und Mineralien supplementieren.",
          ]}
        />
      </Grid>
    </Grid>
  );
};
