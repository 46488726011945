import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { MenuBook } from "@material-ui/icons";
import React from "react";

interface Props {
  tagSlug: string;
  text?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
    },
    buttonContent: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginLeft: 10,
    },
    link: {
      marginTop: 20,
      textDecoration: "none",
    },
  }),
);

export const BibButton: React.FC<Props> = ({ tagSlug, text }) => {
  const classes = useStyles();

  return (
    <a
      href={`https://stockdale-coaching.com/blog/tag/${tagSlug}`}
      className={classes.link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Button variant="outlined" className={classes.button}>
        <div className={classes.buttonContent}>
          <span>{text || "Mehr dazu in der Bibliothek"}</span> <MenuBook className={classes.icon} />
        </div>
      </Button>
    </a>
  );
};
