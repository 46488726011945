import { Grid } from "@material-ui/core";
import React from "react";
import nutritionImage from "../../../../../assets/nutrition.svg";
import { BibButton } from "../../../../../components/BibButton/BibButton";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

// const useStyles = makeStyles(() =>
//   createStyles({
//     subtitle: {
//       margin: "30px 0 0px 0",
//     },
//   })
// );

export const MetabolismPage6: React.FC = () => {
  // const classes = useStyles();
  return (
    <PageLayout title="Der Nährwert-Haushalt" image={nutritionImage}>
      <Grid container justify="center">
        <Checklist
          items={[
            "Grundsätzlich können wir über unsere Nahrungsmittel sämtliche relevanten Nährstoffe aufnehmen.",
            "Es gibt hier allerdings eine Herausforderung: Durch die industrielle Produktion unserer Nahrungsmittel sind gewisse Vitamine und Mineralien nur sehr wenig oder gar nicht mehr in unseren Nahrungsmitteln vorhanden.",
            "Das bedeutet, dass es einen Zusatzbedarf an gewissen Vitaminen und Mineralien geben kann, den wir substituieren müssen, um Mangelerscheinungen vorzubeugen.",
          ]}
        />
        <BibButton tagSlug="naehrwert-haushalt" />
      </Grid>
    </PageLayout>
  );
};
