import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import habitsImage from "../../../../../assets/habits.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

const useStyles = makeStyles(() =>
  createStyles({
    subtitle: {
      margin: "30px 0 0px 0",
    },
  }),
);

export const MindsetPage2: React.FC = () => {
  const classes = useStyles();
  return (
    <PageLayout title="Gewohnheiten" image={habitsImage}>
      <Typography>
        <strong>„Der Mensch ist ein Gewohnheitstier.“</strong>
      </Typography>
      <Typography>
        Um Deine eigene Ernährung zu verstehen, beginnen wir damit Gewohnheiten zu verstehen – denn
        Ernährung ist eine unserer stärksten Gewohnheiten. Am Ende des Tages ist eine Veränderung
        der Ernährung nur dann nachhaltig erfolgreich, wenn es eine Veränderung der Gewohnheit ist.
      </Typography>
      <Typography>
        Das Thema Gewohnheiten ist extrem komplex und umfangreich und ich versuche im Folgenden die
        relevanten Punkte für uns herauszufiltern:
      </Typography>
      <Typography className={classes.subtitle}>
        <strong>Was sind Gewohnheiten?</strong>
      </Typography>
      <Typography>
        Deine Gewohnheiten sind freiwillig oder unfreiwillig eingeübte Verhaltens-, Fühl- oder
        Denkweisen. Sie sind automatisiert und werden unbewusst ausgeführt – man muss nicht mehr
        darüber nachdenken.
      </Typography>
      <Typography className={classes.subtitle}>
        <strong>Warum entwickeln wir Gewohnheiten und warum sind diese wichtig?</strong>
      </Typography>
      <Typography>
        Dein Gehirn sucht Wege Arbeit und Energie zu sparen. Die einfachste Methode dafür ist, immer
        wiederkehrende Aktivitäten und Routinen abzuspeichern und zu automatisieren.
      </Typography>
      <Typography className={classes.subtitle}>
        <strong>Warum sind diese so schwierig zu ändern?</strong>
      </Typography>
      <Typography>
        Deine Gewohnheiten sind tief verankert und mit Deinem bio-chemischen Belohnungssystem
        verbunden.
      </Typography>
      <Typography className={classes.subtitle}>
        <strong>Was bedeutet das für das Thema Ernährung?</strong>
      </Typography>
      <Typography>
        Schlüsselfaktoren um die Gewohnheit „Ernährung“ verändern zu können, sind:
      </Typography>
      <Grid container justify="center">
        <Checklist
          items={[
            "Bewusstsein, DASS Teile Deiner Ernährung automatisiert und unbewusst stattfinden.",
            "Identifizieren WELCHE Teile Deiner Ernährung automatisiert und unbewusst stattfinden.",
            "Definieren welche Gewohnheiten begünstigend sind und Du beibehalten möchtest.",
            "Definieren welche Gewohnheiten belastend sind und Du ändern möchtest.",
          ]}
        />
      </Grid>
    </PageLayout>
  );
};
