export const needsCoaching = (lastCoaching?: Date, lastUpdate?: Date) => {
  // User has no updates (no coaching required)
  if (!lastUpdate) {
    return false;
  }

  // Last update is older than two days (no coaching required)
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
  if (lastUpdate.getTime() < twoDaysAgo.getTime()) {
    return false;
  }

  // User has never been coached (coaching required)
  if (!lastCoaching) {
    return true;
  }

  // Last update is newer than last coaching (coaching required)
  return lastUpdate.getTime() > lastCoaching.getTime();
};

export const getLastUpdate = (dates: Array<Date | undefined>) =>
  dates.filter((date): date is Date => Boolean(date)).sort((a, b) => b.getTime() - a.getTime())[0];
