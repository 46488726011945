import { Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  CoachingStep,
  getCoachingStepByIndex,
  getCoachingStepIndexByStep,
} from "../../models/coaching-steps";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    stepLabel: {
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "40px",
      },
    },
    stepContent: {
      "& span": {
        color: theme.palette.primary.main,
      },
      cursor: "initial",
    },
    labelContainer: {
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
    },
  }),
);

function getSteps() {
  return [
    "Food Audit",
    "Kopfsache",
    "Stoffwechsel",
    "Nahrungsmittel & Inspirationen",
    "Live Session",
    "Planung & Umsetzung",
  ];
}

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return (
        <Typography>
          7-tägige <span>Dokumentation</span> Deiner Mahlzeiten mit anschließender{" "}
          <span>Analyse</span> und <span>Handlungsempfehlung</span>
        </Typography>
      );
    case 1:
      return (
        <Typography>
          Gemeinsamer Austausch zu Deinen <span>Zielen</span>, <span>Motivation </span> und{" "}
          <span>Hürden</span>
        </Typography>
      );
    case 2:
      return (
        <Typography>
          Theorieteil zum Thema <span>Stoffwechsel</span>: Energie-Haushalt, Säure-Basen-Haushalt,
          Nährwert-Haushalt
        </Typography>
      );
    case 3:
      return (
        <Typography>
          Verbindung des <span>Theorieteils Stoffwechsel</span> mit praktischen{" "}
          <span>Nahrungsmitteln-Tipps und Rezepten</span>
        </Typography>
      );
    case 4:
      return (
        <Typography>
          Zusammen finden wir heraus, ob Du <span>alles verstanden</span> hast und bereit für die
          Umsetzung bist.
        </Typography>
      );
    case 5:
      return (
        <Typography>
          <span>Gemeinsame Planung</span> und <span>Umsetzung</span> Deiner individuellen Ernährung
          in einem 4-Wochen-Plan
        </Typography>
      );
    default:
      return "";
  }
}

export enum CoachingStepNavigationVariant {
  DESKTOP,
  MOBILE,
}

type Props = {
  step: CoachingStep;
  onStep: (step: CoachingStep) => void;
  variant: CoachingStepNavigationVariant;
};

export const CoachingStepNavigation: React.FC<Props> = ({ step, onStep, variant }) => {
  const classes = useStyles();
  const steps = getSteps();

  const handleNextSelect = (index: number) => () => {
    onStep(getCoachingStepByIndex(index));
  };

  const shouldRenderLabel = (currentIndex: number) => {
    // Always render labels on desktops
    if (variant === CoachingStepNavigationVariant.DESKTOP) {
      return true;
    }

    // Render label on mobile for current step and last step.
    // But do not render label for last step if current step is second last
    return (
      getCoachingStepIndexByStep(step) === currentIndex ||
      (currentIndex === steps.length - 1 && getCoachingStepIndexByStep(step) !== steps.length - 2)
    );
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={getCoachingStepIndexByStep(step)}
        alternativeLabel={variant === CoachingStepNavigationVariant.MOBILE}
        orientation={variant === CoachingStepNavigationVariant.DESKTOP ? "vertical" : "horizontal"}
      >
        {steps.map((label, index) => (
          <Step className={classes.stepLabel} key={label}>
            <StepLabel
              onClick={handleNextSelect(index)}
              classes={{ labelContainer: classes.labelContainer }}
            >
              {shouldRenderLabel(index) && label}
            </StepLabel>
            {variant === CoachingStepNavigationVariant.DESKTOP && (
              <StepContent className={classes.stepContent}>{getStepContent(index)}</StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
