import { Button, createStyles, Grid, makeStyles, MobileStepper, Theme } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { useAuth } from "../../../hooks/auth";
import { showErrorToast } from "../../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../../utils/i18n";

type Props = {
  steps: JSX.Element[];
  activeStep: number;
  onSubmit: () => Promise<void>;
  onChangeStep: (step: number) => Promise<void>;
  submitDisabled?: boolean;
  submitButtonText?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& strong": {
        color: theme.palette.primary.main,
      },
      height: "100%",
    },
    pagination: {
      border: "none",
    },
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 120,
    },
    resetButton: {
      color: theme.palette.grey[700],
    },
  }),
);

export const PageStepper: React.FC<Props> = ({
  steps,
  activeStep,
  onChangeStep,
  onSubmit,
  submitDisabled,
  submitButtonText,
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const [pending, setPending] = useState(false);
  const maxSteps = steps.length - 1;

  if (!user) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      setPending(true);
      await onSubmit();
      ref.current?.scrollIntoView({
        behavior: "smooth",
      });
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  const handleNext = async () => {
    if (activeStep < maxSteps) {
      await onChangeStep(activeStep + 1);
      ref.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleBack = async () => {
    if (activeStep > 0) {
      await onChangeStep(activeStep - 1);
      ref.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleReset = async () => {
    await onChangeStep(0);
    ref.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div ref={ref} className={classes.root}>
      {steps[activeStep] || null}
      <MobileStepper
        className={classes.pagination}
        variant="progress"
        elevation={0}
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            className={classes.button}
            variant={activeStep === maxSteps ? "contained" : "text"}
            color={activeStep === maxSteps ? "primary" : "default"}
            onClick={activeStep === maxSteps ? handleSubmit : handleNext}
            disabled={pending || (submitDisabled && activeStep === maxSteps)}
          >
            {activeStep === maxSteps ? submitButtonText || "Absenden" : "Weiter"}
            {activeStep !== maxSteps && <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button
            className={classes.button}
            onClick={handleBack}
            disabled={pending || activeStep === 0}
          >
            <KeyboardArrowLeft /> Zurück
          </Button>
        }
      />
      <Grid container justify="center">
        <Grid item>
          {activeStep > 0 && (
            <Button variant="text" onClick={handleReset} className={classes.resetButton}>
              Zum Anfang
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
