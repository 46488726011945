import {
  Avatar,
  Button,
  Container,
  Grid,
  Link as MuiLink,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { LockOpenOutlined } from "@material-ui/icons";
import { Link, RouteComponentProps } from "@reach/router";
import React, { ChangeEvent, useRef, useState } from "react";
import { Alert } from "../../components/Alert/Alert";
import { sendPasswordResetEmail } from "../../firebase";
import { paths } from "../../routes/routes";
import { showSuccessToast } from "../../utils/app-toast";
import styles from "./ForgotPasswordView.module.css";

export const ForgotPasswordView: React.FC<RouteComponentProps> = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [pending, setPending] = useState(false);

  const onSignup = async () => {
    try {
      setPending(true);
      await sendPasswordResetEmail(email);
      showSuccessToast(
        "Wir haben dir eine E-Mail mit allen Informationen geschickt. Bitte überprüfe jetzt Dein E-Mail Postfach.",
      );
    } catch (error) {
      emailRef.current?.focus();
      setError(
        "Wir konnten Deinen Account nicht finden. Bitte überprüfe Deine E-Mail Adresse und versuche es erneut.",
      );
    } finally {
      setPending(false);
    }
  };

  const onChange = (set: typeof setEmail) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    set(value);
  };

  return (
    <div className={styles.container}>
      <Container component="main" maxWidth="sm">
        <Paper>
          <div className={styles.root}>
            <Avatar className={styles.avatar}>
              <LockOpenOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Du hast Dein Passwort vergessen?
            </Typography>
            <p>
              Hier kannst Du Dein Passwort zurücksetzen. Verrate uns einfach Deine E-Mail Adresse
              und wir schicken dir sofort eine E-Mail und kümmern uns um den Rest.
            </p>
            <div className={styles.form}>
              {Boolean(error) ? <Alert severity="error">{error}</Alert> : null}
              <TextField
                inputRef={emailRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-Mail Adresse"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                onChange={onChange(setEmail)}
                value={email}
                error={Boolean(error)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submit}
                onClick={onSignup}
                disabled={pending}
              >
                Passwort zurücksetzen
              </Button>
              <Grid container>
                <Grid item xs>
                  <MuiLink to={paths.ROOT} component={Link} variant="body2">
                    Zurück zum Login
                  </MuiLink>
                </Grid>
                <Grid item>
                  <MuiLink to={paths.SIGNUP} component={Link} variant="body2">
                    Noch kein Account?
                  </MuiLink>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </Container>
    </div>
  );
};
