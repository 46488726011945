import { Dialog, Fab, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Close } from "@material-ui/icons";
import React from "react";
import ReactPlayer from "react-player";
import { Photo } from "../../models/photo";
import styles from "./PhotoModal.module.css";

type Props = {
  photo: Photo;
  onClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PhotoModal: React.FC<Props> = ({ photo, onClose }) => {
  return (
    <Dialog
      open={photo !== null}
      onClose={onClose}
      TransitionComponent={Transition}
      // Prevents page jump
      disableRestoreFocus
      maxWidth="sm"
    >
      <div className={styles.closeButtonContainer}>
        <Fab
          color="primary"
          size="small"
          aria-label="Schließen"
          className={styles.closeButton}
          onClick={onClose}
        >
          <Close />
        </Fab>
      </div>
      {photo.isVideo ? (
        <ReactPlayer playing={true} url={photo.url} controls width="100%" />
      ) : (
        <img src={photo.url} className={styles.photo} alt="" />
      )}
    </Dialog>
  );
};
