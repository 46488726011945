import { Router } from "@reach/router";
import React from "react";
import { Helmet } from "react-helmet";
import { APP_CONST } from "./assets/constants";
import { AppBar } from "./components/AppBar/AppBar";
import { useAuth } from "./hooks/auth";
import { useIsCoach } from "./hooks/is-coach";
import { paths } from "./routes/routes";
import { CoachingView } from "./views/Coaching/CoachingView";
import { CoachingUserDetailView } from "./views/Coaching/UserDetail/UserDetailView";
import { DashboardView } from "./views/Dashboard/DashboardView";
import { ForgotPasswordView } from "./views/ForgotPassword/ForgotPasswordView";
import { LoginView } from "./views/Login/LoginView";
import { ProfileView } from "./views/Profile/ProfileView";
import { RecipesView } from "./views/Recipes/RecipesView";
import { SignupView } from "./views/Signup/SignupView";
import { TemplatesView } from "./views/Templates/TemplatesView";

function App() {
  const { user } = useAuth();
  const isCoach = useIsCoach();

  return (
    <>
      <Helmet>
        <title>{APP_CONST.SEO.TITLE}</title>
        <meta name="description" content={APP_CONST.SEO.DESCRIPTION} />
      </Helmet>
      {user ? (
        <>
          <AppBar />
          <Router>
            <DashboardView path={paths.ROOT} default />
            <ProfileView path={paths.PROFILE} />
            {isCoach && <CoachingView path={paths.COACHING} />}
            {isCoach && <CoachingUserDetailView path={paths.COACHING_USER_DETAIL} />}
            {isCoach && <TemplatesView path={paths.TEMPLATES} />}
            {isCoach && <RecipesView path={paths.RECIPES} />}
          </Router>
        </>
      ) : (
        <Router style={{ height: "100%" }}>
          <LoginView path={paths.ROOT} default />
          <SignupView path={paths.SIGNUP} />
          <ForgotPasswordView path={paths.FORGOT_PASSWORD} />
        </Router>
      )}
    </>
  );
}

export default App;
