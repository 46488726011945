import Compressor from "compressorjs";

export const compress = (file: File) => {
  return new Promise<File>((resolve) => {
    new Compressor(file, {
      quality: 0.5,
      success: (blob) => {
        resolve(new File([blob], file.name));
      },
      error: (error) => {
        console.warn("Could not compress file", error, file);
        resolve(file);
      },
    });
  });
};
