import { Typography } from "@material-ui/core";
import React from "react";
import foodImage from "../../../../../assets/food.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

export const RecipesPage2: React.FC = () => {
  return (
    <PageLayout title="Nahrungsmittel & Inspirationen" image={foodImage}>
      <Typography paragraph>
        Der „trockene“ Theorieteil ist geschafft, jetzt geht‘s ans Eingemachte und Du kannst Dein
        neues Wissen anwenden.
      </Typography>
      <Typography>
        <strong>
          Hier lernst Du, wie Du in der Praxis die 3 Haushalte Deinen Zielen entsprechend
          ausbalancieren kannst:
        </strong>
      </Typography>
      <Checklist
        items={[
          "In einem modularen System bekommst Du einen Überblick über die Charakteristika der Nahrungsmittel bezüglich der 3 Haushalte.",
          "Du bekommst Anregungen, wie Du Dir Deine Mahlzeiten so zusammenstellen kannst, dass sie auf Deine Ziele einzahlen und Dir schmecken.",
          "Und ich gebe Dir abschließend beispielhaft einen Überblick, wie ich das Modul selbst anwende.",
        ]}
      />
    </PageLayout>
  );
};
