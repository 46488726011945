import { Button, CardContent, createStyles, Dialog, Fab, makeStyles, Slide, TextField, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Close } from "@material-ui/icons";
import { navigate } from "@reach/router";
import React, { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import { deleteUser } from "../../firebase";
import { PerformanceUser } from "../../models/performance-user";
import { paths } from "../../routes/routes";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import styles from "./DeleteUserModal.module.css";

type Props = {
  userToDelete: PerformanceUser;
  onClose: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      background: "#f44336",
      color: "white",
      marginTop: 20,
    },
    content: {
      marginTop: 80,
      display: "flex",
      flexDirection: "column",
      width: "100%"
    }
  }),
);


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteUserModal: React.FC<Props> = ({ userToDelete, onClose }) => {
  const [verificationText, setVerificationText] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const handleDeleteUser = async () => {
    try {
      setLoading(true)
      await deleteUser(userToDelete.uid)
      toast.success("Nutzer erfolgreich gelöscht")
      navigate(paths.COACHING)
    } catch (error) {
      console.log(error)
      toast.error("Nutzer konnte nicht gelöscht werden")
    } finally {
      setLoading(false)
    }
  }

  const classes = useStyles();

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setVerificationText(value);

  const userName = userToDelete.displayName.trim() || (userToDelete.customName || "").trim() || userToDelete.email.trim();

  const content = (
    <>
      <Typography variant="body1" gutterBottom style={{ marginBottom: "20px" }}>
        Um den Nutzer zu löschen gib <strong>{userName}</strong> in das Textfeld ein.
    </Typography>
      <TextField
        value={verificationText}
        onChange={onChange}
        variant="outlined"
        style={{ marginBottom: "20px" }}
      />
      <Button
        variant="contained"
        onClick={handleDeleteUser}
        disabled={verificationText.trim() !== userName}
        className={classes.deleteButton}
      >
        Nutzer endgültig löschen
  </Button>
    </>
  )

  return (
    <Dialog
      open={userToDelete !== null}
      onClose={onClose}
      TransitionComponent={Transition}
      // Prevents page jump
      disableRestoreFocus
      maxWidth="sm"
    >
      <div className={styles.closeButtonContainer}>
        <Fab
          color="primary"
          size="small"
          aria-label="Schließen"
          className={styles.closeButton}
          onClick={onClose}
        >
          <Close />
        </Fab>
      </div>
      <CardContent className={classes.content}>
        {loading ? <> 
          <LoadingIndicator />
          Nutzer wird gelöscht
        </> : content}
      </CardContent >
    </Dialog>
  );
};
