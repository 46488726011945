import { Grid, Typography } from "@material-ui/core";
import { stringify } from "querystring";
import React from "react";
import image from "../../../../assets/calendar.svg";
import { Checklist } from "../../../../components/Checklist/Checklist";
import { useAuth } from "../../../../hooks/auth";
import { getFirstNameOrNothing } from "../../../../utils/i18n";
import { PageLayout } from "../PageLayout";
import styles from "./MeetStep.module.css";

export const MeetStep: React.FC = () => {
  const { user } = useAuth();
  const nameOrNothing = getFirstNameOrNothing(user);

  const calendlyQueryOptions = stringify({
    background_color: "FFFFFF",
    text_color: "000000",
    primary_color: "60B736",
    full_name: user?.displayName,
    email: user?.email,
  });

  const calendlySrc = `https://calendly.com/stockdale-coaching?${calendlyQueryOptions}`;

  return (
    <PageLayout title="Live Session" image={image}>
      <Typography paragraph>
        Hallo{nameOrNothing}, ich freue mich sehr auf unsere Live Session und darauf, Dich noch
        besser kennenzulernen.
      </Typography>
      <Typography color="primary">
        <strong>In unserer Live Session werden wir folgende Punkte besprechen:</strong>
      </Typography>
      <Checklist
        items={[
          "Sind die Inhalte für Dich bisher klar und verständlich rübergekommen? Wenn nicht, beantworte ich Dir gerne alle offenen Fragen.",
          "Dann gehen wir gemeinsam in die Planung. Und planen gemeinsam, WAS Du genau umsetzen möchtest und WIE Du es umsetzen möchtest.",
        ]}
      />
      <Typography color="primary">
        <strong>
          Lass mich einfach wissen, wann Du Zeit hast und buche Dir hier einen Termin:
        </strong>
      </Typography>
      <Grid container direction="column" justify="center" alignItems="center">
        <div className={styles.wrapper}>
          <iframe
            className={styles.iframe}
            src={calendlySrc}
            frameBorder="0"
            title="Stockdale Coaching Termin"
          ></iframe>
        </div>
      </Grid>
    </PageLayout>
  );
};
