import {
  Avatar,
  Button,
  Grid,
  Link as MuiLink,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { LockOpenOutlined } from "@material-ui/icons";
import { Link, RouteComponentProps } from "@reach/router";
import React, { useRef, useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { APP_CONST } from "../../assets/constants";
import appLogo from "../../assets/logo.png";
import { Alert } from "../../components/Alert/Alert";
import { loginWithEmail, loginWithFacebook } from "../../firebase";
import { paths } from "../../routes/routes";
import styles from "./LoginView.module.css";

export const LoginView: React.FC<RouteComponentProps> = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<null | string>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pending, setPending] = useState(false);

  const firebase = useFirebase();

  function loginWithGoogle() {
    return firebase.login({ provider: "google", type: "redirect" });
  }

  const onLogin = async () => {
    try {
      setPending(true);
      await loginWithEmail(email, password);
    } catch /*  */ {
      emailRef.current?.focus();
      setError(
        "Login fehlgeschlagen. Bitte überprüfe Deine E-Mail Adresse und Dein Passwort und versuche es erneut.",
      );
    } finally {
      setPending(false);
    }
  };

  const onChange = (set: typeof setEmail) => ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    set(value);
  };

  const onEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await onLogin();
    }
  };

  return (
    <Grid container component="main" className={styles.root}>
      <Grid item xs={false} md={6} className={styles.image} />
      <Grid
        item
        xs={12}
        md={6}
        component={Paper}
        elevation={6}
        square
        container
        alignItems="center"
        className={styles.paper}
      >
        <Grid container justify="center" alignItems="center">
          <div className={styles.logoWrapper}>
            <a href={APP_CONST.WEBSITE_URL} target="_blank" rel="noreferrer">
              <img className={styles.logo} alt=" Stockdale|coaching" src={appLogo} />
            </a>
          </div>
          <Grid item xs={10} sm={8} md={8} xl={6} className={styles.content}>
            <Avatar className={styles.avatar}>
              <LockOpenOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Einloggen
            </Typography>
            <div className={styles.buttons}>
              <FacebookLoginButton
                onClick={loginWithFacebook}
                text="Mit Facebook einloggen"
                className={styles.loginButton}
              />
              <GoogleLoginButton
                onClick={loginWithGoogle}
                text="Mit Google einloggen"
                className={styles.loginButton}
              />
            </div>
            <div className={styles.separator}>oder</div>
            {Boolean(error) ? <Alert severity="error">{error}</Alert> : null}
            <div className={styles.form}>
              <TextField
                inputRef={emailRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-Mail Adresse"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                onChange={onChange(setEmail)}
                value={email}
                error={Boolean(error)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={onChange(setPassword)}
                onKeyUp={onEnter}
                value={password}
                error={Boolean(error)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submit}
                onClick={onLogin}
                disabled={pending}
              >
                Jetzt einloggen
              </Button>
              <Grid container>
                <Grid item xs>
                  <MuiLink to={paths.FORGOT_PASSWORD} component={Link} variant="body2">
                    Passwort vergessen?
                  </MuiLink>
                </Grid>
                <Grid item>
                  <MuiLink to={paths.SIGNUP} component={Link} variant="body2">
                    Noch kein Account?
                  </MuiLink>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
