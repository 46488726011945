import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import diaryImage from "../../../../../assets/diary.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { RecipesGrid } from "../../../../../components/RecipesGrid/RecipesGrid";
import { PageLayout } from "../../PageLayout";

type AdvanceFunction = () => unknown;

interface Props {
  onAdvance: AdvanceFunction | null;
}

export const RecipesPage7: React.FC<Props> = ({ onAdvance }) => {
  return (
    <div>
      <PageLayout title="Inspirationen" image={diaryImage}>
        <Typography paragraph>
          Wie bereits geschrieben, habe ich bewusst auf fixe Rezepte verzichtet. (Für die, die
          interessiert sind, werde ich aber nach und nach Rezepte in{" "}
          <strong>
            <a
              href="https://stockdale-coaching.com/blog/"
              target="_blank"
              rel="noreferrer noopener"
            >
              meinem Blog
            </a>
          </strong>{" "}
          veröffentlichen).
        </Typography>
        <Typography paragraph>
          Warum? Ich habe keine Ahnung, was Dir schmeckt und was nicht – ich könnte hier also
          dutzende Rezepte vorschlagen und keines derer mundet Dir.
        </Typography>
        <Typography paragraph>
          Allerdings habe ich die letzten Jahre meine <strong>eigene Ernährung</strong> dokumentiert
          – ursprünglich nur für mich, um meinen Veränderungsprozess zu beobachten. Und ich gebe es
          zu: Die Fotos sind nicht schön, aber zumindest authentisch.
        </Typography>
        <Typography paragraph>
          Diese teile ich gerne mit Dir, um einen Eindruck zu vermitteln, wie meine
          Ernährungsgewohnheiten jetzt ausschauen.
        </Typography>
        <Typography paragraph>
          Das chaotische Sammelsurium habe ich ein wenig segmentiert und in meine Herausforderungen
          und Lösungsansätze eingeteilt.
        </Typography>
        <Typography paragraph>
          <strong>Diese Punkte haben mich beschäftigt:</strong>
        </Typography>
        <Checklist
          items={[
            "Was kann ich einfach, schnell, abwechslungsreich und in größeren Mengen kochen?",
            "Wie werde ich rein pflanzlich satt? Wie ersetze ich z.B. meinen geliebten Käse und mein Frühstücksomelette?",
            "Wie schaffe ich es auch im Urlaub - oder wenn ich beruflich unterwegs bin - mich meinen Zielen entsprechend zu ernähren?",
            "Wie ernähre ich mich - wenn ich zum Beispiel für einen Marathon trainiere - so, dass ich genug Energie habe, aber nicht zu voll bin?",
          ]}
        />
      </PageLayout>
      {onAdvance ? (
        <PageLayout>
          <Grid container justify="center" alignItems="center">
            <Typography paragraph>
              <strong>
                Nachdem Du Dich hast inspirieren lassen, kannst Du selbständig in die nächste Phase
                wechseln.
              </strong>
            </Typography>
            <Button variant="contained" color="primary" onClick={onAdvance}>
              Jetzt Live Session vereinbaren
            </Button>
          </Grid>
        </PageLayout>
      ) : null}
      <PageLayout>
        <RecipesGrid />
      </PageLayout>
    </div>
  );
};
