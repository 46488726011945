import { Button, TextField } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { updateNotes } from "../../../../../hooks/users";
import { PerformanceUser } from "../../../../../models/performance-user";
import { showErrorToast } from "../../../../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../../../../utils/i18n";

export const NotesTab: React.FC<{ user: PerformanceUser }> = ({ user }) => {
  const [pending, setPending] = useState(false);
  const [notes, setNotes] = useState<string>(user.notes);

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setNotes(value);

  const handleSubmit = async () => {
    try {
      setPending(true);
      await updateNotes(user.uid, notes);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      <TextField
        value={notes}
        onChange={onChange}
        variant="outlined"
        multiline
        rows={25}
        fullWidth
        disabled={pending}
      />
      <Button
        onClick={handleSubmit}
        disabled={pending || notes === user.notes}
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
      >
        Speichern
      </Button>
    </>
  );
};
