import {
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import {
  CoachingStepNavigation,
  CoachingStepNavigationVariant,
} from "../../components/CoachingStepNavigation/CoachingStepNavigation";
import { Onboarding } from "../../components/Onboarding/Onboarding";
import { useAuth } from "../../hooks/auth";
import { removeUserFlag } from "../../hooks/users";
import { CoachingStep, getAllowedCoachingSteps } from "../../models/coaching-steps";
import { UserFlag } from "../../models/flags";
import { getFirstNameOrNothing } from "../../utils/i18n";
import styles from "./DashboardView.module.css";
import { FoodAuditStep } from "./Steps/FoodAudit/FoodAuditStep";
import { MeetStep } from "./Steps/Meet/MeetStep";
import { MetabolismStep } from "./Steps/Metabolism/MetabolismStep";
import { MindthingStep } from "./Steps/Mindset/MindsetStep";
import { MonitoringStep } from "./Steps/Monitoring/MonitoringStep";
import { NotReadyStep } from "./Steps/NotReady/NotReadyStep";
import { RecipesStep } from "./Steps/Recipes/RecipesStep";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcome: {
      // color: theme.palette.primary.main,
      color: theme.palette.primary.main,
      opacity: 0.6,
      marginBottom: 20,
    },
    card: {
      padding: 20,
      marginBottom: 20,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    caption: {
      opacity: 0.8,
      marginLeft: 10,
    },
  }),
);

export const DashboardView: React.FC<RouteComponentProps> = () => {
  const { user } = useAuth();
  const classes = useStyles();
  const [onboardingVisible, setOnboardingVisible] = useState(false);
  const [onboardingSkipped, setOnboardingSkipped] = useState(false);
  const [coachingStep, setCoachingStep] = useState<CoachingStep>(
    user?.coachingStep || CoachingStep.FOOD_AUDIT,
  );

  useEffect(() => {
    if (user?.flags) {
      setOnboardingVisible(user.flags.includes(UserFlag.ONBOARDING));
    }
  }, [user?.flags]);

  useEffect(() => {
    if (user?.coachingStep) {
      setCoachingStep(user.coachingStep);
    }
  }, [user?.coachingStep]);

  const handleOnboardingFinish = () => {
    removeUserFlag(UserFlag.ONBOARDING, user?.uid);
  };

  const handleOnboardingSkip = () => {
    setOnboardingVisible(false);
    setOnboardingSkipped(true);
  };

  const handleCoachingStep = (step: CoachingStep) => setCoachingStep(step);

  const nameOrNothing = getFirstNameOrNothing(user);

  const renderCurrentStep = () => {
    if (!user || !coachingStep) {
      return null;
    }

    if (!getAllowedCoachingSteps(user.coachingStep).includes(coachingStep)) {
      return <NotReadyStep />;
    }

    switch (coachingStep) {
      case CoachingStep.FOOD_AUDIT:
        return <FoodAuditStep />;
      case CoachingStep.MIND_THING:
        return <MindthingStep />;
      case CoachingStep.METABOLISM:
        return <MetabolismStep />;
      case CoachingStep.RECIPES:
        return <RecipesStep />;
      case CoachingStep.MEET:
        return <MeetStep />;
      case CoachingStep.PLANNING:
        return <MonitoringStep />;
      default:
        return null;
    }
  };

  const dashboard = (
    <Container maxWidth="lg">
      <Typography className={classes.welcome} variant="h3">
        Hallo{nameOrNothing}!
      </Typography>
      <Grid container component="main" spacing={4}>
        <Hidden smDown>
          <Grid item xs={false} md={4}>
            <CoachingStepNavigation
              step={coachingStep}
              onStep={handleCoachingStep}
              variant={CoachingStepNavigationVariant.DESKTOP}
            />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12} md={false}>
            <CoachingStepNavigation
              step={coachingStep}
              onStep={handleCoachingStep}
              variant={CoachingStepNavigationVariant.MOBILE}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={8}>
          {renderCurrentStep()}
        </Grid>
      </Grid>
    </Container>
  );

  if (!user) {
    return null;
  }

  return (
    <div className={styles.root}>
      {onboardingVisible && !onboardingSkipped ? (
        <Onboarding onSkip={handleOnboardingSkip} onFinish={handleOnboardingFinish} />
      ) : (
        dashboard
      )}
    </div>
  );
};
