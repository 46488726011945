export const APP_CONST = {
  DOMAIN: "stockdale-coaching.com",
  WEBSITE_URL: "https://stockdale-coaching.com/",
  EMAIL: "greg@stockdale-coaching.com",
  SEO: {
    TITLE: "App | Stockdale Coaching | Personal Online Coaching",
    DESCRIPTION:
      "Nahrung für Deine Ziele. Gemeinsam finden wir heraus, was Du erreichen möchtest, planen den Weg dahin und ich begleite Dich in der Umsetzung –erfolgreich und nachhaltig.",
  },
  ADDRESS: {
    NAME: "Greg Stockdale",
    STREET: "Heideweg 14",
    TOWN: "57439 Attendorn",
    TAX_ID: "338/5160/1381",
  },
  SOCIAL: [],
};
