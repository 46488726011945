import { Card, CardContent, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: "20px",
    },
    header: {
      marginTop: "10px",
      marginBottom: "20px",
    },
    icon: {
      maxHeight: 120,
      maxWidth: 120,
      margin: "0 0 20px 0",
    },
  }),
);

type PageLayoutProps = {
  title?: string;
  image?: string;
};

export const PageLayout: React.FC<PageLayoutProps> = ({ title, image, children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.header}
        >
          {image && <img className={classes.icon} src={image} alt=""></img>}
          {title && <Typography variant="h4">{title}</Typography>}
        </Grid>
        {children}
      </CardContent>
    </Card>
  );
};
