import {
  Avatar,
  Badge,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Typography,
} from "@material-ui/core";
import { Fastfood, Notes, Restaurant, Search, SpeakerNotesOutlined } from "@material-ui/icons";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Link, RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { CoachingActions } from "../../../components/CoachingActions/CoachingActions";
import { DeleteUserModal } from "../../../components/DeleteUserModal/DeleteUserModal";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { PhotoGrid, PhotoGridVariant } from "../../../components/PhotoGrid/PhotoGrid";
import { addDailyMessage, deleteDailyMessage, likePhoto } from "../../../firebase";
import { isCoach } from "../../../hooks/is-coach";
import { usePhotos } from "../../../hooks/photos";
import { useUser } from "../../../hooks/users";
import { DailyMessage, PerformanceUser } from "../../../models/performance-user";
import { Photo } from "../../../models/photo";
import { buildUserDetailPath, paths } from "../../../routes/routes";
import { getLastUpdate, needsCoaching } from "../../../utils/date";
import { getMotivationText } from "../../../utils/i18n";
import { AnalysisTab } from "./Tabs/AnalysisTab/AnalysisTab";
import { HomeworkTab } from "./Tabs/HomeworkTab/HomeworkTab";
import { MonitoringTab } from "./Tabs/MonitoringTab/MonitoringTab";
import { NotesTab } from "./Tabs/NotesTab/NotesTab";
import styles from "./UserDetailView.module.css";

enum Tabs {
  FOOD_AUDIT = "FOOD_AUDIT",
  ANALYSIS = "ANALYSIS",
  HOMEWORK = "HOMEWORK",
  MONITORING = "MONITORING",
  NOTES = "NOTES",
}

const useStyles = makeStyles(() =>
  createStyles({
    tabPanel: {
      marginTop: 20,
      padding: 0,
    },
    tabList: {
      overflowX: "auto",
    },
    deleteButton: {
      background: "#f44336",
      color: "white",
      marginTop: 20,
    },
  }),
);

export const CoachingUserDetailView: React.FC<
  RouteComponentProps<{
    uid?: string;
  }>
> = ({ uid }) => {
  const user = useUser(uid);
  const { photos, updatePhotos, loading: photosLoading } = usePhotos(uid);

  const [tab, setTab] = useState(Tabs.FOOD_AUDIT);

  const [userToDelete, setUserToDelete] = useState<PerformanceUser | null>(null);

  const classes = useStyles();

  useEffect(() => {
    if (!user?.uid) {
      return;
    }
    updatePhotos();
    // Re-fetch photos if a new picture has been added or liked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid, user?.lastPhotoUpload, user?.likedPhotos]);

  if (!user) {
    return null;
  }

  const onCloseDeleteUserModal = () => setUserToDelete(null);

  const handleDeleteUser = async () => {
    setUserToDelete(user);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: Tabs) => {
    setTab(newValue);
  };

  const onPhotoLike = async (photo: Photo) => likePhoto(user.uid, photo.fullPath);

  const onAddDailyMessage = (message: DailyMessage) => addDailyMessage(user.uid, message);

  const onDeleteDailyMessage = (message: DailyMessage) => deleteDailyMessage(user.uid, message);

  return (
    <div className={styles.root}>
      <Container maxWidth="lg">
        <Breadcrumbs className={styles.breadcrumbs}>
          <Link to={paths.COACHING}>Alle Benutzer</Link>
          <Link to={buildUserDetailPath(user.uid)}>
            {user.customName || user.displayName || user.email}
          </Link>
        </Breadcrumbs>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <Card>
              <CardContent>
                <Grid container alignItems="center" direction="column">
                  <Avatar src={user.photoURL} className={styles.avatar} />
                  <Typography gutterBottom variant="h5" component="h2">
                    {user.customName || user.displayName}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    <a href={`mailto:${user.email}`} target="_blank" rel="noreferrer">
                      {user.email}
                    </a>
                  </Typography>
                  {user.motivation.map((m) => (
                    <Chip
                      color="primary"
                      key={m}
                      label={getMotivationText(m)}
                      className={styles.chip}
                    />
                  ))}
                </Grid>
              </CardContent>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <CardContent>
                <CoachingActions user={user} />
              </CardContent>
            </Card>
            {!isCoach(user.uid) && (
              <Card style={{ marginTop: "20px" }}>
                <CardContent>
                  <Typography color="primary" variant="subtitle2">
                    Danger Zone
                  </Typography>
                  <Grid container justify="center">
                    <Button
                      variant="contained"
                      onClick={handleDeleteUser}
                      className={classes.deleteButton}
                    >
                      Nutzer löschen
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <TabContext value={tab}>
              <Paper>
                <TabList
                  className={classes.tabList}
                  value={tab}
                  textColor="primary"
                  onChange={handleTabChange}
                >
                  <Tab
                    label="Food Audit"
                    value={Tabs.FOOD_AUDIT}
                    icon={
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={!needsCoaching(user.lastCoaching, user.lastPhotoUpload)}
                      >
                        <Fastfood />
                      </Badge>
                    }
                  />
                  <Tab label="Analyse" icon={<Search />} value={Tabs.ANALYSIS} />
                  <Tab
                    label="Hausaufgaben"
                    icon={
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={
                          !needsCoaching(
                            user.lastCoaching,
                            getLastUpdate([
                              user.lastMindsetUpdate,
                              user.lastMetabolismUpdate,
                              user.lastMonitoringUpdate,
                            ]),
                          )
                        }
                      >
                        <SpeakerNotesOutlined />
                      </Badge>
                    }
                    value={Tabs.HOMEWORK}
                  />
                  <Tab
                    label="Umsetzung"
                    icon={
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={
                          !needsCoaching(
                            user.lastCoaching,
                            getLastUpdate([user.lastMonitoringWeeksUpdate]),
                          )
                        }
                      >
                        <Restaurant />
                      </Badge>
                    }
                    value={Tabs.MONITORING}
                  />
                  <Tab label="Notizen" icon={<Notes />} value={Tabs.NOTES} />
                </TabList>
              </Paper>
              <TabPanel className={classes.tabPanel} value={Tabs.FOOD_AUDIT}>
                {photosLoading ? (
                  <LoadingIndicator />
                ) : (
                  <PhotoGrid
                    user={user}
                    photos={photos}
                    onLike={onPhotoLike}
                    onAddDailyMessage={onAddDailyMessage}
                    onDeleteDailyMessage={onDeleteDailyMessage}
                    variant={PhotoGridVariant.COACHING_VIEW}
                  />
                )}
              </TabPanel>
              <TabPanel className={classes.tabPanel} value={Tabs.ANALYSIS}>
                <AnalysisTab user={user} />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value={Tabs.HOMEWORK}>
                <HomeworkTab user={user} />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value={Tabs.MONITORING}>
                <MonitoringTab user={user} />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value={Tabs.NOTES}>
                <NotesTab user={user} />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Container>
      {userToDelete && (
        <DeleteUserModal userToDelete={userToDelete} onClose={onCloseDeleteUserModal} />
      )}
    </div>
  );
};
