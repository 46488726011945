import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import dinnerImage from "../../../../../assets/dinner.svg";
import foodImage from "../../../../../assets/food.svg";
import { PageLayout } from "../../PageLayout";

const useStyles = makeStyles(() =>
  createStyles({
    mobileScrollContainer: {
      marginTop: 40,
      height: "100%",
      overflowX: "auto",
    },
  }),
);

export const RecipesPage6: React.FC = () => {
  const classes = useStyles();
  return (
    <PageLayout title="Mittag- und Abendessen" image={foodImage}>
      <Typography paragraph>
        In diesem Modul habe ich beispielhaft zusammengestellt, welche Nahrungsmittel und Zutaten
        ich nutze, um meine Lieblingsmahlzeiten zuzubereiten. Wie bereits erwähnt, diese sind nicht
        vollständig – Du kannst diese nach deinem Geschmack ergänzen.
      </Typography>
      <Typography paragraph>
        Du siehst, die Nahrungsmittel sind <strong>rein pflanzlich</strong>. Möchtest Du zum
        Beispiel Fleisch, Fisch, Ei und Käse ergänzen, dann kannst Du es gerne tun.{" "}
        <strong>Vergiss aber nicht:</strong> Diese sollten nicht mehr als ca. 20% der Gesamtmahlzeit
        ausmachen, da sie sauer verstoffwechselt werden.
      </Typography>
      <div className={classes.mobileScrollContainer}>
        <img src={dinnerImage} alt="" />
      </div>
    </PageLayout>
  );
};
