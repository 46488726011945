import { Grid, Typography } from "@material-ui/core";
import React from "react";
import pointLeftImage from "../../../../../assets/point-left.svg";
import {
  MotivationCard,
  MOTIVATIONS,
} from "../../../../../components/MotivationCard/MotivationCard";
import { useAuth } from "../../../../../hooks/auth";
import { PageLayout } from "../../PageLayout";

export const MonitoringPage1: React.FC = () => {
  const { user } = useAuth();
  const motivation = MOTIVATIONS.find((m) => user?.motivation.includes(m.type));
  return (
    <PageLayout title="Planung & Umsetzung">
      <Typography paragraph>
        Vielen Dank für die klasse Live Session, es war schön, Dich persönlich zu sprechen.
      </Typography>
      <Typography paragraph>
        Wie abgemacht, bitte ich Dich im Folgeschritt Deine <strong>„WAS"</strong> und Deine{" "}
        <strong>„WIE"</strong> Ziele noch einmal klar zu definieren.
      </Typography>
      <Typography paragraph>
        Des Weiteren stehen dir eine Einkaufsliste und ein Tagebuch zur Verfügung. Dort kannst Du
        Deine Einkäufe planen und festhalten, wie es dir mit den Veränderungen geht.
      </Typography>
      <Typography paragraph>
        Hier noch mal Dein <strong>übergeordnetes Ziel</strong>:
      </Typography>
      {motivation && (
        <Grid container justify="center" alignItems="center">
          <MotivationCard title={motivation.title} selected={true} onClick={() => {}} />
          <img height={120} src={pointLeftImage} alt="" />
        </Grid>
      )}
    </PageLayout>
  );
};
