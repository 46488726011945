import { createStyles, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import metabolismImage from "../../../../../assets/metabolism.svg";
import { Alert } from "../../../../../components/Alert/Alert";
import { useAuth } from "../../../../../hooks/auth";
import { CoachingStep } from "../../../../../models/coaching-steps";
import { PageLayout } from "../../PageLayout";

const useStyles = makeStyles(() =>
  createStyles({
    subtitle: {
      margin: "20px 0 0 0",
    },
  }),
);

type Props = {
  metabolismHomework: string;
  onChange: (metabolismHomework: string) => void;
};

export const MetabolismPageHomework: React.FC<Props> = ({ metabolismHomework, onChange }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChange(value);

  const isDisabled = user?.coachingStep !== CoachingStep.METABOLISM;

  return (
    <PageLayout title="Hausaufgabe" image={metabolismImage}>
      <Typography paragraph>
        Damit ich mich optimal auf unser Gespräch vorbereiten kann, habe ich eine weitere kleine
        Hausaufgabe für Dich.
      </Typography>
      <Typography paragraph>
        Bitte versuche das Gelernte in Deinen <strong>eigenen Worten</strong> zu beschreiben. Welche
        Informationen waren neu und/oder überraschend für dich.
        <br />
        Und falls Du etwas nicht verstanden hast, kannst du natürlich auch Fragen stellen.
      </Typography>
      <Typography className={classes.subtitle}>
        <strong>Beschreibe in Deinen eigenen Worten, was Du in Stoffwechsel gelernt hast:</strong>
      </Typography>
      <TextField
        placeholder="Ich habe gelernt, dass"
        variant="outlined"
        multiline
        rows={8}
        fullWidth
        disabled={isDisabled}
        onChange={handleChange}
        value={metabolismHomework}
      />
      {user?.coachingStep === CoachingStep.METABOLISM ? null : (
        <Alert severity="info">
          Du kannst die Hausaufgabe nur editierien wenn Du Dich im Abschnitt "Stoffwechsel"
          befindest.
        </Alert>
      )}
      <Typography className={classes.subtitle}>
        <strong>
          Ich werde mir Deine Hausaufgabe aufmerksam durchlesen. Diese besprechen wir dann gemeinsam
          in der Live Session.
        </strong>
      </Typography>
    </PageLayout>
  );
};
