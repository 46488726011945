import { createStyles, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import kopfsacheImage from "../../../../../assets/kopfsache.svg";
import { Alert } from "../../../../../components/Alert/Alert";
import { useAuth } from "../../../../../hooks/auth";
import { CoachingStep } from "../../../../../models/coaching-steps";
import { MindsetHomework } from "../../../../../models/performance-user";
import { PageLayout } from "../../PageLayout";

const useStyles = makeStyles(() =>
  createStyles({
    subtitle: {
      margin: "20px 0 0 0",
    },
  }),
);

type Props = {
  mindsetHomework: MindsetHomework;
  onChange: (mindsetHomework: MindsetHomework) => void;
};

export const MindsetPageHomework: React.FC<Props> = ({ mindsetHomework, onChange }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const handleChange = (key: keyof MindsetHomework) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...mindsetHomework,
      [key]: value,
    });

  const isDisabled = user?.coachingStep !== CoachingStep.MIND_THING;

  return (
    <PageLayout title="Hausaufgabe" image={kopfsacheImage}>
      <Typography>
        Für diese Hausaufgabe nehme Dir ausreichend Zeit und gehe in Dich. Sie ist besonders
        wichtig, da sie das Fundament für die nächsten Wochen legt.
        <br />
        <br />
        Keine Sorge, nichts was Du hier schreibst ist in Stein gemeißelt. Alles lebt und fließt.
        Sollten neue Informationen und Erfahrungswerte zu Änderungen führen, dann ist das eine
        wichtige Entwicklung.
        <br />
        <br />
        Nichtsdestotrotz, sei achtsam...
      </Typography>
      <Typography className={classes.subtitle}>
        <strong>Motivation</strong>
      </Typography>
      <TextField
        placeholder="Was sind Deine Motivationsfaktoren? Wofür möchtest Du Dich verändern?"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        disabled={isDisabled}
        onChange={handleChange("motivation")}
        value={mindsetHomework.motivation}
      />
      <Typography className={classes.subtitle}>
        <strong>Ziele</strong>
      </Typography>
      <TextField
        placeholder="Welche Ziele setzt Du Dir (quantitativ und qualitativ)? (Definiere die Ziele SMART)"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        disabled={isDisabled}
        onChange={handleChange("goals")}
        value={mindsetHomework.goals}
      />
      <Typography className={classes.subtitle}>
        <strong>Gewohnheiten</strong>
      </Typography>
      <TextField
        placeholder={`Identifiziere welche Teile Deiner Ernährung eine Gewohnheit sind; automatisiert und unbewusst stattfinden. Definiere welche Gewohnheiten begünstigend sind und Du beibehalten möchtest.\nDefiniere auch welche Gewohnheiten belastend sind und Du ändern möchtest.`}
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        disabled={isDisabled}
        onChange={handleChange("habits")}
        value={mindsetHomework.habits}
      />
      <Typography className={classes.subtitle}>
        <strong>Herausforderungen</strong>
      </Typography>
      <TextField
        placeholder="Wenn Du Deine definierten Motivationen, Ziele und Ernährungsgewohnheiten analysierst: Wo siehst Du Deine Hauptherausforderungen?"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        disabled={isDisabled}
        onChange={handleChange("challenges")}
        value={mindsetHomework.challenges}
      />
      {user?.coachingStep === CoachingStep.MIND_THING ? null : (
        <Alert severity="info">
          Du kannst die Hausaufgabe nur editierien wenn Du Dich im Abschnitt "Kopfsache" befindest.
        </Alert>
      )}
      <Typography className={classes.subtitle}>
        <strong>
          Ich werde mir Deine Hausaufgabe aufmerksam durchlesen. Diese besprechen wir dann gemeinsam
          in der Live Session.
        </strong>
      </Typography>
    </PageLayout>
  );
};
