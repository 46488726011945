import { CoachingStep } from "../models/coaching-steps";
import { MotivationType } from "../models/motivation";
import { PerformanceUser } from "../models/performance-user";
import { TemplateCategory } from "../models/template";

export const DEFAULT_ERROR_MESSAGE = "Es ist ein Fehler aufgetreten. Bitte versuche es erneut.";

export const getFirstNameOrNothing = (user?: PerformanceUser | null) => {
  const displayName = user?.displayName;
  const customName = user?.customName;

  const firstName = (customName || displayName || "").split(" ")[0].trim();

  if (!firstName) {
    return "";
  }

  return ` ${firstName}`;
};

export const getMotivationText = (motivation: MotivationType) => {
  switch (motivation) {
    case MotivationType.LOOSE_FAT:
      return "Körperfett";
    case MotivationType.IMMUNE_SYSTEM:
      return "Immunsystem";
    case MotivationType.PERFORMANCE:
      return "Leistungsfähigkeit";
    case MotivationType.BETTER_OVERALL:
      return "Mentale Gesundheit";
    case MotivationType.ILLNESS_PREVENTION:
      return "Krankheitssymptome";
    case MotivationType.MORE_VEGAN:
      return "Weniger Fleisch";
  }
};

export const getTemplateCategoryText = (category: TemplateCategory) => {
  switch (category) {
    case TemplateCategory.FOOD_AUDIT:
      return "Food Audit";
    case TemplateCategory.HANDLUNGSEMPFEHLUNG:
      return "Handlungsempfehlung";
    case TemplateCategory.OUTRO:
      return "Verabschiedung";
    case TemplateCategory.NONE:
      return "Freitext";
    default:
      return "Unbekannte Kategorie";
  }
};

export const getCoachingStepText = (coachingStep: CoachingStep) => {
  switch (coachingStep) {
    case CoachingStep.FOOD_AUDIT:
      return "Food Audit";
    case CoachingStep.MIND_THING:
      return "Kopfsache";
    case CoachingStep.METABOLISM:
      return "Stoffwechsel";
    case CoachingStep.RECIPES:
      return "Nahrungsmittel";
    case CoachingStep.MEET:
      return "Live Session";
    case CoachingStep.PLANNING:
      return "Umsetzung";
  }
};
