import { Typography } from "@material-ui/core";
import React from "react";
import metabolismImage from "../../../../../assets/metabolism.svg";
import { PageLayout } from "../../PageLayout";

export const MetabolismPage2: React.FC = () => {
  return (
    <PageLayout title="Stoffwechsel I" image={metabolismImage}>
      <Typography>
        <strong>Kurzdefinition von Stoffwechsel:</strong>
      </Typography>
      <Typography paragraph>
        Generell umfasst der Stoffwechsel sämtliche chemischen und physikalischen Vorgänge, die die
        zugeführten Nährstoffe in Deinem Körper umwandeln.
      </Typography>
      <Typography>
        <strong>Grundsätzlich gibt es 2 Unterscheidungen:</strong>
      </Typography>
      <Typography paragraph>
        Baustoffwechsel: biochemische Vorgänge, die dem Aufbau, Abbau und Ersatz bzw. Erhalt der
        Körpersubstanz dienen.
      </Typography>
      <Typography paragraph>
        Energiestoffwechsel: Energiegewinnung für Deine energie-verbrauchenden Aktivitäten und damit
        der Aufrechterhaltung Deiner Körperfunktionen.
      </Typography>
      <Typography>
        <strong>Transport:</strong>
      </Typography>
      <Typography paragraph>
        Der Blutkreislauf ist das Verteilungssystem, um die Nährstoffe in Deine Körperzellen zu
        transportieren und zu verteilen.
      </Typography>
      <Typography>
        <strong>Einfach gesagt:</strong>
      </Typography>
      <Typography>
        Stoffwechsel ist alles, was Du isst und trinkst, wo es hin transportiert wird und wie es
        umgewandelt wird.
      </Typography>
    </PageLayout>
  );
};
