import { useSelector } from "react-redux";
import { PerformanceUser } from "../models/performance-user";
import { AppState } from "../redux";
import { mapUser } from "./users";

export const useAuth = () => {
  const profile: PerformanceUser & {
    isLoaded: boolean;
    isEmpty: boolean;
  } = useSelector<AppState, any>((state) => state.firebase.profile);

  if (!profile.isLoaded || profile.isEmpty || !profile.uid) {
    return { user: null, isLoaded: profile.isLoaded };
  }

  const user = mapUser(profile.uid, profile);

  return { user, isLoaded: profile.isLoaded };
};
