import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useRecipes } from "../../hooks/recipes";
import { Recipe } from "../../models/recipe";
import { RecipeCard } from "../RecipeCard/RecipeCard";
import { RecipeModal } from "../RecipeModal/RecipeModal";

const useStyles = makeStyles(() =>
  createStyles({
    category: {
      marginBottom: "40px",
    },
  }),
);

const CATEGORY_SORT_ORDER = [
  "wok-gerichte",
  "frühstück",
  "urlaub",
  "training",
  "fleisch",
  "gewürze",
];

const findSortIndex = (category: string) => {
  for (let index = 0; index < CATEGORY_SORT_ORDER.length; index++) {
    if (category.toLowerCase().match(CATEGORY_SORT_ORDER[index].toLowerCase())) {
      return index;
    }
  }

  return 1000000;
};

export const RecipesGrid: React.FC = () => {
  const classes = useStyles();
  const recipes = useRecipes();
  const byCategory = recipes
    .filter((recipe) => recipe.active)
    .reduce(
      (acc, recipe) => ({
        ...acc,
        [recipe.category]: (acc[recipe.category] || []).concat(recipe),
      }),
      {} as Record<string, Recipe[]>,
    );
  // TODO sort by category

  const [openRecipe, setOpenRecipe] = useState<Recipe | null>(null);

  const onOpenRecipe = (recipe: Recipe) => setOpenRecipe(recipe);

  const onCloseRecipe = () => setOpenRecipe(null);

  return (
    <>
      {Object.keys(byCategory)
        .sort((a, b) => findSortIndex(a) - findSortIndex(b))
        .map((category) => (
          <div key={category} className={classes.category}>
            <Typography variant="h4" color="primary" gutterBottom>
              {category}
            </Typography>
            <Grid container spacing={2}>
              {byCategory[category].map((recipe) => (
                <Grid key={recipe.id} item xs={12} sm={6} md={4} lg={4}>
                  <RecipeCard recipe={recipe} onOpenRecipe={onOpenRecipe} />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      {openRecipe && <RecipeModal recipe={openRecipe} onClose={onCloseRecipe} />}
    </>
  );
};
