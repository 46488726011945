import { Grid } from "@material-ui/core";
import React from "react";
import acidImage from "../../../../../assets/acid.svg";
import { BibButton } from "../../../../../components/BibButton/BibButton";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

// const useStyles = makeStyles(() =>
//   createStyles({
//     subtitle: {
//       margin: "30px 0 0px 0",
//     },
//   })
// );

export const MetabolismPage5: React.FC = () => {
  // const classes = useStyles();
  return (
    <PageLayout title="Der Säure-Basen-Haushalt" image={acidImage}>
      <Grid container justify="center">
        <Checklist
          items={[
            "Der Säure-Basen Haushalt unseres Körpers ist messbar am pH-Wert (unter 7 = sauer / 7 = neutral / über 7 = basisch). Der Körper versucht einen pH-Wert von 7,4 zu halten, da Werte unter 7,35 stark gesundheitsgefährdend sein können.",
            "Eine Übersäuerung des Körpers kann zu Mineralstoffmangel, Übergewicht, Überbelastung der Organe, Vermehrung von ungewünschten Bakterien und Pilzen und einer Schwächung des Immunsystems führen.",
            "Nahrungsmittel wirken sich entweder basisch, neutral oder sauer auf den Körper aus. Um die genannten gesundheitlichen Konsequenzen eines übersäuerten Körpers zu vermeiden, sollten wir darauf achten, ca. 80% neutrale und basische Nahrungsmittel zu uns zu nehmen.",
          ]}
        />
        <BibButton tagSlug="saeure-basen-haushalt" />
      </Grid>
    </PageLayout>
  );
};
