import { Paper } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { MotivationType } from "../../models/motivation";
import styles from "./MotivationCard.module.css";

export type MotivationData = {
  type: MotivationType;
  title: any;
};

export const MOTIVATIONS: MotivationData[] = [
  {
    type: MotivationType.LOOSE_FAT,
    title: (
      <>
        Ich möchte abnehmen bzw. meinen <strong>Körperfettanteil senken</strong>.
      </>
    ),
  },
  {
    type: MotivationType.IMMUNE_SYSTEM,
    title: (
      <>
        {" "}
        Ich möchte mein <strong>Immunsystem stärken</strong>.
      </>
    ),
  },
  {
    type: MotivationType.PERFORMANCE,
    title: (
      <>
        {" "}
        Ich möchte meine <strong>sportliche Leistungsfähigkeit</strong> steigern.
      </>
    ),
  },
  {
    type: MotivationType.BETTER_OVERALL,
    title: (
      <>
        Ich möchte mental <strong>frischer</strong>, <strong>klarer</strong>,{" "}
        <strong>fitter</strong> werden.
      </>
    ),
  },
  {
    type: MotivationType.MORE_VEGAN,
    title: (
      <>
        {" "}
        Ich möchte <strong>weniger Fleisch- und Milchprodukte zu mir nehmen.</strong>{" "}
      </>
    ),
  },
];

type Props = {
  title: string;
  selected: boolean;
  onClick: () => void;
};

export const MotivationCard: React.FC<Props> = ({ title, selected, onClick }) => {
  return (
    <Paper
      onClick={onClick}
      elevation={2}
      className={classNames(styles.motivation, {
        [styles.selected]: selected,
        [styles.notselected]: !selected,
      })}
    >
      <div>{title}</div>
    </Paper>
  );
};
