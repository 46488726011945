import {
  Avatar,
  Button,
  Grid,
  Link as MuiLink,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { LockOpenOutlined } from "@material-ui/icons";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import React, { ChangeEvent, useRef, useState } from "react";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { Alert } from "../../components/Alert/Alert";
import {
  loginWithEmail,
  loginWithFacebook,
  loginWithGoogle,
  signupWithEmail,
} from "../../firebase";
import { paths } from "../../routes/routes";
import styles from "./SignupView.module.css";

export const SignupView: React.FC<RouteComponentProps> = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<null | string>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pending, setPending] = useState(false);

  const onSignup = async () => {
    try {
      setPending(true);
      await signupWithEmail(email, password);
      await navigate(paths.ROOT, { replace: true });
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case "auth/invalid-email":
          setError("Deine E-Mail Adresse ist ungültig. Bitte überprüfe sie noch einmal.");
          emailRef.current?.focus();
          break;
        case "auth/weak-password":
          setError("Dein Passwort ist zu kurz. Bitte wähle ein anderes Passwort.");
          passwordRef.current?.focus();
          break;
        case "auth/email-already-in-use":
          try {
            await loginWithEmail(email, password);
          } catch (error) {
            setError("Diese E-Mail Adresse wird bereits benutzt.");
            passwordRef.current?.focus();
          }
          break;
        default:
          setError(`Bei der Anmeldung ist ein Fehler aufgetreten: "${error.code}"`);
          emailRef.current?.focus();
          break;
      }
    } finally {
      setPending(false);
    }
  };

  const onChange = (set: typeof setEmail) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    set(value);
  };

  return (
    <Grid container component="main" className={styles.root}>
      <Grid item xs={false} md={6} className={styles.image} />
      <Grid
        item
        xs={12}
        md={6}
        component={Paper}
        elevation={6}
        square
        container
        alignItems="center"
      >
        <Grid container justify="center" alignItems="center">
          <Grid item xs={10} sm={8} md={8} xl={6} className={styles.content}>
            <Avatar className={styles.avatar}>
              <LockOpenOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Anmeldung
            </Typography>
            <p>Du kannst Dich ganz einfach mit Deinem Facebook oder Google Account anmelden.</p>
            <div className={styles.buttons}>
              <FacebookLoginButton
                onClick={loginWithFacebook}
                text="Mit Facebook anmelden"
                className={styles.loginButton}
              />
              <GoogleLoginButton
                onClick={loginWithGoogle}
                text="Mit Google anmelden"
                className={styles.loginButton}
              />
            </div>
            <div className={styles.separator}>oder mit Deiner E-Mail Adresse</div>

            <div className={styles.form}>
              {Boolean(error) ? <Alert severity="error">{error}</Alert> : null}
              <TextField
                inputRef={emailRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Deine E-Mail Adresse"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                onChange={onChange(setEmail)}
                value={email}
                error={Boolean(error)}
              />
              <TextField
                inputRef={passwordRef}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Wähle ein Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={onChange(setPassword)}
                value={password}
                error={Boolean(error)}
              />
              <div>
                Mit der Bestätigung des Formulars stimme ich den <a href="https://stockdale-coaching.com/agb" target="_blank" rel="noreferrer noopener">
                  Allgemeinen Geschäftsbedingungen</a>, sowie der <a href="https://stockdale-coaching.com/datenschutz" target="_blank" rel="noreferrer noopener">Datenschutzerklärung</a> zu. Ich habe die <a href="https://stockdale-coaching.com/widerrufsbelehrung" target="_blank" rel="noreferrer noopener">Wiederrufsbelehrung</a> zur Kenntnis genommen.
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submit}
                onClick={onSignup}
                disabled={pending}
              >
                Jetzt anmelden
              </Button>
              <Grid container justify="center">
                <MuiLink to={paths.ROOT} component={Link} variant="body2">
                  Du hast bereits einen Account?
                </MuiLink>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
