export enum CoachingStep {
  FOOD_AUDIT = "FOOD_AUDIT",
  MIND_THING = "MIND_THING",
  METABOLISM = "METABOLISM",
  RECIPES = "RECIPES",
  MEET = "CALL",
  PLANNING = "PLANNING",
}

const ORDERED_STEPS = [
  CoachingStep.FOOD_AUDIT,
  CoachingStep.MIND_THING,
  CoachingStep.METABOLISM,
  CoachingStep.RECIPES,
  CoachingStep.MEET,
  CoachingStep.PLANNING,
];

export const getCoachingStepByIndex = (index: number) => ORDERED_STEPS[index];

export const getCoachingStepIndexByStep = (step: CoachingStep) =>
  ORDERED_STEPS.findIndex((i) => step === i);

export const getAllowedCoachingSteps = (step: CoachingStep) => {
  const stepIndex = getCoachingStepIndexByStep(step);
  return ORDERED_STEPS.filter((_, index) => index <= stepIndex);
};
