import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { CoachingStep } from "../models/coaching-steps";
import { UserFlag } from "../models/flags";
import { MotivationType } from "../models/motivation";
import {
  FoodAuditAnalysis,
  MindsetHomework,
  MonitoringWeek,
  PerformanceUser,
} from "../models/performance-user";
import { getLastUpdate } from "../utils/date";

export const mapUser = (uid: string, data: any): PerformanceUser => {
  const lastLogin = data.lastLogin?.seconds ? new Date(data.lastLogin.seconds * 1000) : undefined;

  const lastPhotoUpload = data.lastPhotoUpload?.seconds
    ? new Date(data.lastPhotoUpload.seconds * 1000)
    : undefined;

  const lastMindsetUpdate = data.lastMindsetUpdate?.seconds
    ? new Date(data.lastMindsetUpdate.seconds * 1000)
    : undefined;

  const lastMetabolismUpdate = data.lastMetabolismUpdate?.seconds
    ? new Date(data.lastMetabolismUpdate.seconds * 1000)
    : undefined;

  const lastMonitoringUpdate = data.lastMonitoringUpdate?.seconds
    ? new Date(data.lastMonitoringUpdate.seconds * 1000)
    : undefined;

  const lastMonitoringWeeksUpdate = data.lastMonitoringWeeksUpdate?.seconds
    ? new Date(data.lastMonitoringWeeksUpdate.seconds * 1000)
    : undefined;

  const lastCoaching = data.lastCoaching?.seconds
    ? new Date(data.lastCoaching.seconds * 1000)
    : undefined;

  const lastUpdate = getLastUpdate([
    lastPhotoUpload,
    lastMindsetUpdate,
    lastMetabolismUpdate,
    lastMonitoringUpdate,
    lastMonitoringWeeksUpdate,
  ]);

  return {
    uid: uid,
    customName: data.customName || "",
    displayName: data.displayName || "",
    email: data.email || "",
    phoneNumber: data.phoneNumber || "",
    photoURL: data.photoURL || "",
    notes: data.notes || "",
    lastUpdate,
    lastLogin,
    lastPhotoUpload,
    lastMindsetUpdate,
    lastMetabolismUpdate,
    lastMonitoringUpdate,
    lastMonitoringWeeksUpdate,
    lastCoaching,
    likedPhotos: data.likedPhotos || [],
    flags: data.flags || [],
    mindsetStep: data.mindsetStep || 0,
    metabolismStep: data.metabolismStep || 0,
    recipesStep: data.recipesStep || 0,
    monitoringStep: data.monitoringStep || 0,
    motivation: data.motivation
      ? Array.isArray(data.motivation)
        ? data.motivation
        : [data.motivation]
      : [],
    coachingStep: data.coachingStep || CoachingStep.FOOD_AUDIT,
    dailyMessages: (data.dailyMessages || []).map((message: any) => ({
      id: message.id,
      text: message.text || "",
      dateCreated: message.dateCreated?.seconds
        ? new Date(message.dateCreated.seconds * 1000)
        : new Date(),
      day: message.day?.seconds
        ? new Date(message.day.seconds * 1000)
        : message.dateCreated?.seconds
        ? new Date(message.dateCreated.seconds * 1000)
        : new Date(),
      ...(message.weekId && { weekId: message.weekId }),
    })),
    foodAuditAnalysisEnabled: data.foodAuditAnalysisEnabled || false,
    foodAuditAnalysis: {
      intro: data.foodAuditAnalysis?.intro || "",
      outro: data.foodAuditAnalysis?.outro || "",
      acidDescription: data.foodAuditAnalysis?.acidDescription || "",
      energyDescription: data.foodAuditAnalysis?.energyDescription || "",
      nutritionDescription: data.foodAuditAnalysis?.nutritionDescription || "",
      acid: data.foodAuditAnalysis?.acid || "",
      energy: data.foodAuditAnalysis?.energy || "",
      nutrition: data.foodAuditAnalysis?.nutrition || "",
      acidConclusion: data.foodAuditAnalysis?.acidConclusion || "",
      energyConclusion: data.foodAuditAnalysis?.energyConclusion || "",
      nutritionConclusion: data.foodAuditAnalysis?.nutritionConclusion || "",
    },
    mindsetHomework: {
      motivation: data.mindsetHomework?.motivation || "",
      goals: data.mindsetHomework?.goals || "",
      habits: data.mindsetHomework?.habits || "",
      challenges: data.mindsetHomework?.challenges || "",
    },
    metabolismHomework: data.metabolismHomework || "",
    monitoringWeeks: data.monitoringWeeks || [],
    monitoringWhat: data.monitoringWhat || "",
    monitoringHow: data.monitoringHow || "",
    outroPageText: data.outroPageText || "",
    outroPageEnabled: data.outroPageEnabled || false,
  };
};

export const useUser = (uid?: string) => {
  const [user, setUser] = useState<PerformanceUser | null>(null);

  useEffect(() => {
    if (!uid) {
      setUser(null);
      return;
    }

    return firebase
      .firestore()
      .doc(`users/${uid}`)
      .onSnapshot((doc) => {
        const data = doc.data();

        if (!data) {
          setUser(null);
          return;
        }

        setUser(mapUser(uid, data));
      });
  }, [uid]);

  return user;
};

export const useUsers = () => {
  const [users, setUsers] = useState<PerformanceUser[]>([]);

  useEffect(
    () =>
      firebase
        .firestore()
        .collection("users")
        .onSnapshot((docs) => {
          setUsers(
            docs.docs.map((doc) => {
              const data = doc.data();
              return mapUser(doc.id, data);
            }),
          );
        }),
    [],
  );

  return users;
};

export const addUserFlag = (flag: UserFlag, uid?: string) => {
  if (!uid) {
    return;
  }
  firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      flags: firebase.firestore.FieldValue.arrayUnion(flag),
    });
};

export const removeUserFlag = (flag: UserFlag, uid?: string) => {
  if (!uid) {
    return;
  }
  firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      flags: firebase.firestore.FieldValue.arrayRemove(flag),
    });
};

export const updateMotivation = (motivation: MotivationType[], uid?: string) => {
  if (!uid) {
    return;
  }
  firebase.firestore().doc(`users/${uid}`).update({
    motivation,
  });
};

export const updateDisplayName = (name: string, uid?: string) => {
  if (!uid) {
    return;
  }
  firebase.firestore().doc(`users/${uid}`).update({
    customName: name,
  });
};

export const updateCoachingStep = (uid: string, coachingStep: CoachingStep) =>
  firebase.firestore().doc(`users/${uid}`).update({
    coachingStep,
    lastCoaching: new Date(),
  });

export const updateFoodAuditAnalysisEnabled = (uid: string, foodAuditAnalysisEnabled: boolean) =>
  firebase.firestore().doc(`users/${uid}`).update({
    foodAuditAnalysisEnabled,
    lastCoaching: new Date(),
  });

export const updateFoodAuditAnalysis = (uid: string, foodAuditAnalysis: FoodAuditAnalysis) =>
  firebase.firestore().doc(`users/${uid}`).update({
    foodAuditAnalysis,
    lastCoaching: new Date(),
  });

export const updatemindsetHomework = (uid: string, mindsetHomework: MindsetHomework) =>
  firebase.firestore().doc(`users/${uid}`).update({
    mindsetHomework,
    lastMindsetUpdate: new Date(),
  });

export const updateNotes = (uid: string, notes: string) =>
  firebase.firestore().doc(`users/${uid}`).update({
    notes,
  });

export const updatemindsetStep = (uid: string, mindsetStep: number) =>
  firebase.firestore().doc(`users/${uid}`).update({
    mindsetStep,
  });

export const updateMetabolismStep = (uid: string, metabolismStep: number) =>
  firebase.firestore().doc(`users/${uid}`).update({
    metabolismStep,
  });

export const updateRecipesStep = (uid: string, recipesStep: number) =>
  firebase.firestore().doc(`users/${uid}`).update({
    recipesStep,
  });

export const updateMonitoringStep = (uid: string, monitoringStep: number) =>
  firebase.firestore().doc(`users/${uid}`).update({
    monitoringStep,
  });

export const updateMetabolismHomework = (uid: string, metabolismHomework: string) =>
  firebase.firestore().doc(`users/${uid}`).update({
    metabolismHomework,
    lastMetabolismUpdate: new Date(),
  });

export const addMonitoringWeek = (uid: string) => {
  const newWeek: MonitoringWeek = {
    id: v4(),
    mood: 1,
    shoppingList: "",
    thoughts: "",
    startDate: new Date(),
  };
  return firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      monitoringWeeks: firebase.firestore.FieldValue.arrayUnion(newWeek),
    });
};

export const updateMonitoringWeek = async (uid: string, week: MonitoringWeek) => {
  const doc = await firebase.firestore().doc(`users/${uid}`).get();

  const user = doc.data();

  if (!user) {
    console.error(`Could not get user ${uid}`);
    return Promise.reject();
  }

  return firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      monitoringWeeks: mapUser(uid, user).monitoringWeeks.map((w) => (w.id === week.id ? week : w)),
      lastMonitoringWeeksUpdate: new Date(),
    });
};

export const deleteMonitoringWeek = (uid: string, week: MonitoringWeek) => {
  // TODO remove images
  return firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      monitoringWeeks: firebase.firestore.FieldValue.arrayRemove(week),
      lastCoaching: new Date(),
    });
};

export const updateMonitoringWhat = (uid: string, text: string) =>
  firebase.firestore().doc(`users/${uid}`).update({
    monitoringWhat: text,
    lastMonitoringUpdate: new Date(),
  });

export const updateMonitoringHow = (uid: string, text: string) =>
  firebase.firestore().doc(`users/${uid}`).update({
    monitoringHow: text,
    lastMonitoringUpdate: new Date(),
  });

export const updateOutroText = (uid: string, outroPageText: string) =>
  firebase.firestore().doc(`users/${uid}`).update({
    outroPageText,
    lastCoaching: new Date(),
  });

export const updateOutroPageEnabled = (uid: string, outroPageEnabled: boolean) =>
  firebase.firestore().doc(`users/${uid}`).update({
    outroPageEnabled,
    lastCoaching: new Date(),
  });
