import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import {
  updateCoachingStep,
  updateFoodAuditAnalysisEnabled,
  updateMonitoringStep,
  updateOutroPageEnabled,
} from "../../hooks/users";
import { CoachingStep } from "../../models/coaching-steps";
import { PerformanceUser } from "../../models/performance-user";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE, getCoachingStepText } from "../../utils/i18n";

type Props = {
  user: PerformanceUser;
};

export const CoachingActions: React.FC<Props> = ({ user }) => {
  const [pending, setPending] = useState(false);

  const handleStepChange = async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    try {
      setPending(true);
      await updateCoachingStep(user.uid, value as CoachingStep);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  const handleAnalysisChange = async ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    try {
      setPending(true);
      await updateFoodAuditAnalysisEnabled(user.uid, checked);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  const handleOutroChange = async ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    try {
      setPending(true);
      await updateOutroPageEnabled(user.uid, checked);
      if (checked) {
        await updateMonitoringStep(user.uid, user.monitoringWeeks.length + 2);
      } else {
        await updateMonitoringStep(user.uid, user.monitoringWeeks.length + 1);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      <Typography color="primary" variant="subtitle2">
        Coaching-Phase
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="step"
          name="step"
          value={user.coachingStep}
          onChange={handleStepChange}
        >
          {Object.values(CoachingStep).map((step) => (
            <FormControlLabel
              key={step}
              value={step}
              control={<Radio color="primary" />}
              label={getCoachingStepText(step)}
              disabled={pending}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Typography color="primary" variant="subtitle2" style={{ marginTop: "20px" }}>
        Coaching-Tools
      </Typography>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={user.foodAuditAnalysisEnabled}
              color="primary"
              onChange={handleAnalysisChange}
              disabled={pending}
            />
          }
          label="Handlungsempfehlung"
        />
        <FormControlLabel
          control={
            <Switch
              checked={user.outroPageEnabled}
              color="primary"
              onChange={handleOutroChange}
              disabled={pending}
            />
          }
          label="Verabschiedung"
        />
      </FormControl>
    </>
  );
};
