import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { ChangeEvent, useState } from "react";
import { deleteUrl, uploadRecipePhoto } from "../../firebase";
import { Recipe } from "../../models/recipe";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/i18n";
import { EditStateIndicator } from "../AccordionInput/AccordionInput";

type Props = {
  onSave: (recipe: Recipe) => Promise<void>;
  onDelete: () => Promise<void>;
  recipe: Recipe;
  expanded: boolean;
  toggleExpanded: AccordionProps["onChange"];
};

export const RecipeEditor: React.FC<Props> = ({
  recipe,
  onSave,
  onDelete,
  expanded,
  toggleExpanded,
}) => {
  const [pending, setPending] = useState(false);
  const [name, setName] = useState<string>(recipe.name);
  const [text, setText] = useState<string>(recipe.text);
  const [link, setLink] = useState<string>(recipe.link || "");
  const [photo, setPhoto] = useState<string>(recipe.photo);
  const [category, setCategory] = useState<string>(recipe.category);
  const [active, setActive] = useState(recipe.active);

  const onChange = (setter: typeof setName) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setter(value);

  const onToggle = async ({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
    setActive(checked);

  const handlePhotoUpload = async ({ target }: any) => {
    try {
      setPending(true);
      const url = await uploadRecipePhoto(target.files[0]);
      setPhoto(url);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  const handleSave = async () => {
    try {
      setPending(true);
      const oldPhoto = recipe.photo;

      await onSave({
        ...recipe,
        name,
        text,
        category,
        photo,
        active,
        link,
      });

      // Delete old photo if photo has changed
      if (oldPhoto && oldPhoto !== photo) {
        deleteUrl(oldPhoto);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  const handleDelete = async () => {
    try {
      setPending(true);
      await onDelete();

      // Delete all photos
      if (photo) {
        deleteUrl(photo).catch((e) => console.error("Could not delete photo", e));
      }
      if (recipe.photo && recipe.photo !== photo) {
        deleteUrl(recipe.photo).catch((e) => console.error("Could not delete photo", e));
      }
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  return (
    <Accordion key={recipe.id} expanded={expanded} onChange={toggleExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container justify="space-between" alignItems="center">
          <Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <EditStateIndicator complete={recipe.active} />
              </Grid>
              <Grid item>{recipe.name}</Grid>
            </Grid>
          </Typography>
          <Typography variant="caption">{recipe.category}</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <TextField
            value={name}
            onChange={onChange(setName)}
            label="Name"
            variant="outlined"
            disabled={pending}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            value={text}
            onChange={onChange(setText)}
            multiline
            rows={10}
            label="Text"
            variant="outlined"
            disabled={pending}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            value={category}
            onChange={onChange(setCategory)}
            label="Kategorie"
            variant="outlined"
            disabled={pending}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            value={link}
            onChange={onChange(setLink)}
            label="Link"
            variant="outlined"
            disabled={pending}
            style={{ marginBottom: "20px" }}
          />
          <img src={photo} alt="" width={200} />
          <input type="file" onChange={handlePhotoUpload} disabled={pending} />
          <FormControlLabel
            control={
              <Switch checked={active} color="primary" onChange={onToggle} disabled={pending} />
            }
            label="Aktiv"
          />
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button size="small" onClick={handleDelete} disabled={pending} style={{ color: "red" }}>
          Rezept löschen
        </Button>
        <Button size="small" color="primary" onClick={handleSave} disabled={pending}>
          Speichern
        </Button>
      </AccordionActions>
    </Accordion>
  );
};
