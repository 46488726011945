import { Button, Container, TextField, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React, { ChangeEvent, useState } from "react";
import { v4 } from "uuid";
import { TemplateEditor } from "../../components/TemplateEditor/TemplateEditor";
import { addTemplate, deleteTemplate, updateTemplate } from "../../firebase";
import { useTemplates } from "../../hooks/templates";
import { Template, TemplateCategory } from "../../models/template";
import { getTemplateCategoryText } from "../../utils/i18n";
import styles from "./TemplatesView.module.css";

export const TemplatesView: React.FC<RouteComponentProps> = () => {
  const templates = useTemplates();
  const [filter, setFilter] = useState("");
  const [expandedTemplate, setExpandedTemplate] = React.useState<string | false>(false);

  const onChangeFilter = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFilter(value);
  };

  const toggleExpandedTemplate = (template: Template) => (
    event: ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpandedTemplate(isExpanded ? template.id : false);
  };

  const handleCreateTemplate = async () => {
    const id = v4();
    await addTemplate({
      id,
      name: "# Neue Vorlage",
      text: "",
      category: TemplateCategory.FOOD_AUDIT,
    });

    setExpandedTemplate(id);

    window.scroll({ top: 0, behavior: "smooth" });
  };

  const handleUpdateTemplate = async (template: Template) => {
    await updateTemplate(template);
    setExpandedTemplate(false);
  };

  const handleDeleteTemplate = (template: Template) => async () => {
    await deleteTemplate(template);
    setExpandedTemplate(false);
  };

  return (
    <div className={styles.root}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Alle Vorlagen
        </Typography>
        <TextField
          variant="outlined"
          label="Filter"
          value={filter}
          fullWidth
          style={{ marginBottom: "10px" }}
          onChange={onChangeFilter}
        />
        {templates
          .filter((t) => t.category !== TemplateCategory.NONE)
          .filter(
            (template) =>
              !filter ||
              template.name.toLowerCase().includes(filter.toLowerCase()) ||
              getTemplateCategoryText(template.category)
                .toLowerCase()
                .includes(filter.toLowerCase()),
          )
          .map((template) => (
            <TemplateEditor
              key={template.id}
              template={template}
              onSave={handleUpdateTemplate}
              onDelete={handleDeleteTemplate(template)}
              expanded={expandedTemplate === template.id}
              toggleExpanded={toggleExpandedTemplate(template)}
            />
          ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateTemplate}
          style={{ marginTop: "20px" }}
        >
          Neue Vorlage
        </Button>
      </Container>
    </div>
  );
};
