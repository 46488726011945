import { firebaseReducer } from "react-redux-firebase";
import { combineReducers, createStore, Reducer, Store } from "redux";
import { firestoreReducer } from "redux-firestore";
import { PerformanceUser } from "../models/performance-user";
export interface AppState {
  //TODO: fix any
  firebase: {
    profile: PerformanceUser;
  } & any;
  //TODO: fix any
  firestore: any;
}

export type AppAction = any;
// & AuthAction;

const createRootReducer = (): Reducer<AppState, AppAction> =>
  combineReducers<AppState>({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });

export const store: Store<AppState, AppAction> = createStore<AppState, AppAction, {}, {}>(
  createRootReducer(),
);
