import React from "react";
import { useAuth } from "../../../../hooks/auth";
import { updateCoachingStep, updateRecipesStep } from "../../../../hooks/users";
import { CoachingStep } from "../../../../models/coaching-steps";
import { PageStepper } from "../PageStepper";
import { RecipesPage1 } from "./Pages/RecipesPage1";
import { RecipesPage2 } from "./Pages/RecipesPage2";
import { RecipesPage3 } from "./Pages/RecipesPage3";
import { RecipesPage4 } from "./Pages/RecipesPage4";
import { RecipesPage5 } from "./Pages/RecipesPage5";
import { RecipesPage6 } from "./Pages/RecipesPage6";
import { RecipesPage7 } from "./Pages/RecipesPage7";

export const RecipesStep: React.FC = () => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  const activeStep = user.recipesStep;

  const setActiveStep = (step: number) => updateRecipesStep(user.uid, step);

  const advanceToLiveSession = async () => {
    await updateCoachingStep(user.uid, CoachingStep.MEET);
  };

  const notInRecipesStep = user.coachingStep !== CoachingStep.RECIPES;

  // Overwrite submit button text to handle MAX_WEEKS
  const submitButtonText = activeStep === 7 ? "Nächster Abschitt" : "Zur Live Session";
  return (
    <PageStepper
      activeStep={activeStep}
      onChangeStep={setActiveStep}
      onSubmit={advanceToLiveSession}
      submitButtonText={submitButtonText}
      submitDisabled={notInRecipesStep}
      steps={[
        <RecipesPage1 />,
        <RecipesPage2 />,
        <RecipesPage3 />,
        <RecipesPage4 />,
        <RecipesPage5 />,
        <RecipesPage6 />,
        <RecipesPage7 onAdvance={notInRecipesStep ? null : advanceToLiveSession} />,
      ]}
    />
  );
};
