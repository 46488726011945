import { Grid } from "@material-ui/core";
import React from "react";
import { updateFoodAuditAnalysis } from "../../hooks/users";
import { FoodAuditAnalysis, PerformanceUser } from "../../models/performance-user";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE, getFirstNameOrNothing } from "../../utils/i18n";
import { AccordionInput } from "../AccordionInput/AccordionInput";

type Props = {
  user: PerformanceUser;
};

export const AnalysisInput: React.FC<Props> = ({ user }) => {
  const handleSubmit = (key: keyof FoodAuditAnalysis) => async (text: string) => {
    try {
      await updateFoodAuditAnalysis(user.uid, {
        ...user.foodAuditAnalysis,
        [key]: text,
      });
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    }
  };

  const name = getFirstNameOrNothing(user);
  const inputs: Array<{
    title: string;
    key: keyof FoodAuditAnalysis;
    defaultText: string;
  }> = [
    {
      title: `${name ? `${name}, ` : ""}Deine persönliche Analyse`,
      defaultText: user.foodAuditAnalysis.intro,
      key: "intro",
    },

    {
      title: "Energie-Haushalt - Erklärung",
      defaultText: user.foodAuditAnalysis.energyDescription,
      key: "energyDescription",
    },
    {
      title: "Dein Energie-Haushalt",
      defaultText: user.foodAuditAnalysis.energy,
      key: "energy",
    },
    {
      title: "Energie-Haushalt - Fazit",
      defaultText: user.foodAuditAnalysis.energyConclusion,
      key: "energyConclusion",
    },
    {
      title: "Säure-Basen-Haushalt - Erklärung",
      defaultText: user.foodAuditAnalysis.acidDescription,
      key: "acidDescription",
    },
    {
      title: "Dein Säure-Basen-Haushalt",
      defaultText: user.foodAuditAnalysis.acid,
      key: "acid",
    },
    {
      title: "Säure-Basen-Haushalt - Fazit",
      defaultText: user.foodAuditAnalysis.acidConclusion,
      key: "acidConclusion",
    },
    {
      title: "Nährwert-Haushalt - Erklärung",
      defaultText: user.foodAuditAnalysis.nutritionDescription,
      key: "nutritionDescription",
    },
    {
      title: "Dein Nährwert-Haushalt",
      defaultText: user.foodAuditAnalysis.nutrition,
      key: "nutrition",
    },
    {
      title: "Nährwert-Haushalt - Fazit",
      defaultText: user.foodAuditAnalysis.nutritionConclusion,
      key: "nutritionConclusion",
    },
    {
      title: "Outro",
      defaultText: user.foodAuditAnalysis.outro,
      key: "outro",
    },
  ];

  return (
    <Grid container direction="column">
      {inputs.map((i) => (
        <AccordionInput
          key={i.key}
          title={i.title}
          defaultText={i.defaultText}
          onSave={handleSubmit(i.key)}
          initiallyExpanded={!!!i.defaultText}
        />
      ))}
    </Grid>
  );
};
