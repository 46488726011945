import { TextField, Typography } from "@material-ui/core";
import React from "react";
import goalsImage from "../../../../../assets/goals.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

type Props = {
  how: string;
  what: string;
  onChange: (how: string, what: string) => Promise<void>;
  disabled: boolean;
};

export const MonitoringPage2: React.FC<Props> = ({ how, what, onChange, disabled }) => {
  const handleMonitoringHowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("chanage how", e.target.value);
    onChange(e.target.value, what);
  };

  const handleMonitoringWhatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("change what", e.target.value);
    onChange(how, e.target.value);
  };

  return (
    <PageLayout title="Zielsetzung" image={goalsImage}>
      <Typography paragraph>
        Behalte folgende Punkte für die Planung und Umsetzung im Kopf:
      </Typography>
      <Checklist
        items={["Keep it simple", "Nicht zu viel Veränderungen auf einmal", "Denke an SMART-Ziele"]}
      />
      <br />
      <Typography>
        Hier definierst Du übergeordnet, <strong>WAS</strong> Du umstellen möchtest.
      </Typography>
      <TextField
        placeholder="Was möchtest Du ändern?"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        onChange={handleMonitoringWhatChange}
        defaultValue={what}
        disabled={disabled}
      />
      <br />
      <br />
      <Typography>
        Hier nimmst Du Deine WAS-Ziele und definierst, <strong>WIE</strong> Du die Umstellung
        angehen möchtest.
      </Typography>
      <TextField
        placeholder="Wie möchtest Du es ändern?"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        onChange={handleMonitoringHowChange}
        defaultValue={how}
        disabled={disabled}
      />
    </PageLayout>
  );
};
