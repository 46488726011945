import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import React from "react";

type Props = {
  title?: string;
  text?: string;
  date?: Date;
};

export const HomeworkCard: React.FC<Props> = ({ title, text, date }) => {
  return (
    <Card>
      <CardHeader
        title={title}
        subheader={date?.toLocaleString("de-de", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      />
      <CardContent>
        <Typography style={{ whiteSpace: "pre-line" }}>{text}</Typography>
      </CardContent>
    </Card>
  );
};
