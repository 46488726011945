import { Typography } from "@material-ui/core";
import React from "react";
import kopfsacheImage from "../../../../../assets/kopfsache.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

export const MindsetPage7: React.FC = () => {
  return (
    <PageLayout title="Fazit" image={kopfsacheImage}>
      <Typography align="center">
        <strong>Das war‘s auch schon! Was haben wir gelernt?</strong>
      </Typography>
      <Checklist
        items={[
          "Gewohnheiten sind tief in Dir verankert, allerdings auch instabil. Wenn Du Deine Gewohnheiten bewusst identifizieren kannst, dann kannst Du diese auch ändern.",
          "Ein Veränderungsprozess, auch wenn er mittel- und langfristig positive Auswirkungen hat, kann in den ersten Tagen und Wochen unangenehm sein. Darauf musst Du vorbereitet sein.",
          "Um mittel- und langfristig erfolgreich zu sein, muss Deine Motivation von innen kommen.",
          "Deine definierten Ziele sollten SMART sein.",
        ]}
      />
    </PageLayout>
  );
};
