import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { HomeworkCard } from "../../../../../components/HomeworkCard/HomeworkCard";
import { PerformanceUser } from "../../../../../models/performance-user";

export const HomeworkTab: React.FC<{ user: PerformanceUser }> = ({ user }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Kopfsache</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeworkCard
            title="Motivation"
            date={user.lastMindsetUpdate}
            text={user.mindsetHomework.motivation}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeworkCard
            title="Ziele"
            date={user.lastMindsetUpdate}
            text={user.mindsetHomework.goals}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeworkCard
            title="Gewohnheiten"
            date={user.lastMindsetUpdate}
            text={user.mindsetHomework.habits}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeworkCard
            title="Herausforderungen"
            date={user.lastMindsetUpdate}
            text={user.mindsetHomework.challenges}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Stoffwechsel</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeworkCard
            title="Hausaufgabe"
            date={user.lastMetabolismUpdate}
            text={user.metabolismHomework}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Planung & Umsetzung</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeworkCard
            title="Was möchtest Du ändern?"
            date={user.lastMonitoringUpdate}
            text={user.monitoringWhat}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HomeworkCard
            title="Wie möchtest Du es ändern?"
            date={user.lastMonitoringUpdate}
            text={user.monitoringHow}
          />
        </Grid>
      </Grid>
    </>
  );
};
