import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import kopfsacheImage from "../../../../../assets/kopfsache.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

const useStyles = makeStyles(() =>
  createStyles({
    subheadline: {
      margin: "30px 0 20px 0",
    },
  }),
);

export const MindsetPage1: React.FC = () => {
  const classes = useStyles();
  return (
    <PageLayout title="Kopfsache" image={kopfsacheImage}>
      <Typography>
        Die Veränderung Deiner Essgewohnheiten beginnt nicht auf Deinem Teller, sondern in Deinem
        Kopf – deshalb starten wir mit diesem Thema.
      </Typography>
      <Typography align="center" className={classes.subheadline}>
        <strong>In Kopfsache lernst Du:</strong>
      </Typography>
      <Grid container justify="center">
        <Checklist
          items={[
            "Welche Ernährungsgewohnheiten Du hast und welche Du ändern möchtest.",
            "Wie Du Deine Ernährungsgewohnheiten verändern möchtest.",
            "Auf welche möglichen Herausforderungen Du dabei treffen kannst.",
            "Wie Du klare und greifbare Ziele für die Veränderung setzen kannst.",
          ]}
        />
      </Grid>
    </PageLayout>
  );
};
