import { useEffect, useState } from "react";
import { deleteUrl, getPhotos } from "../firebase";
import { Photo } from "../models/photo";

export const usePhotos = (uid?: string, weekId?: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [photos, setPhotos] = useState<Photo[]>([]);

  useEffect(() => {
    if (uid && photos.length === 0) {
      setLoading(true);
      getPhotos(uid, weekId)
        .then(setPhotos)
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePhotos = () => {
    setLoading(true);
    getPhotos(uid, weekId)
      .then(setPhotos)
      .finally(() => setLoading(false));
  };

  const handleDelete = async (photo: Photo) => {
    await deleteUrl(photo.url);
    updatePhotos();
  };

  return {
    photos,
    loading,
    updatePhotos,
    handleDelete,
  };
};
