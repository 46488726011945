import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { v4 } from "uuid";
import { mapUser } from "../hooks/users";
import { CoachingStep } from "../models/coaching-steps";
import { UserFlag } from "../models/flags";
import { DailyMessage } from "../models/performance-user";
import { Photo } from "../models/photo";
import { Recipe } from "../models/recipe";
import { Template } from "../models/template";

let firebaseApp: firebase.app.App | null = null;

export const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAtd94NFV6IyU4e_onGw_XgVNE8uONWQY8",
    authDomain: "stockdale-coaching-app-419f0.firebaseapp.com",
    projectId: "stockdale-coaching-app-419f0",
    storageBucket: "stockdale-coaching-app-419f0.appspot.com",
    messagingSenderId: "700636704673",
    appId: "1:700636704673:web:18d6d2690c40273884e5e0",
  };

  firebaseApp = firebase.initializeApp(firebaseConfig);

  firebase
    .firestore()
    .enablePersistence()
    .then(() => console.log("Local persistence enabled"))
    .catch((err: any) => {
      console.log("Local persistence disabled: ", err.code);
      if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

  firebase.auth().onAuthStateChanged(async (u) => {
    if (u?.uid) {
      console.log("User logged in");
      // Make sure user collection is always up to date
      try {
        await firebase.firestore().doc(`users/${u.uid}`).update({
          uid: u.uid,
          displayName: u.displayName,
          email: u.email,
          phoneNumber: u.phoneNumber,
          photoURL: u.photoURL,
          lastLogin: new Date(),
        });
      } catch {
        console.log("New user, creating user info...");
        // Add new user
        await firebase
          .firestore()
          .doc(`users/${u.uid}`)
          .set({
            uid: u.uid,
            displayName: u.displayName,
            email: u.email,
            phoneNumber: u.phoneNumber,
            photoURL: u.photoURL,
            lastLogin: new Date(),
            flags: [UserFlag.ONBOARDING],
            coachingStep: CoachingStep.FOOD_AUDIT,
          });
      }
    } else {
      console.log("User logged out");
    }
  });
};

const euFunctions = () => {
  if (!firebaseApp) {
    throw new Error("firebase app not initialized");
  }
  return firebaseApp.functions("europe-west3");
};

export const loginWithGoogle = () =>
  firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());

export const loginWithFacebook = () =>
  firebase.auth().signInWithRedirect(new firebase.auth.FacebookAuthProvider());

export const loginWithEmail = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const signupWithEmail = (email: string, password: string) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

export const sendPasswordResetEmail = (email: string) =>
  firebase.auth().sendPasswordResetEmail(email);

export const logout = () => firebase.auth().signOut();

export const uploadPhoto = (uid: string, file: File, weekId?: string) => {
  const basePath = weekId ? `users/${uid}/weeks/${weekId}/uploads` : `users/${uid}/uploads`;

  // Upload
  const uploadTask = firebase.storage().ref(`${basePath}/${v4()}-${file.name}`).put(file);

  // Update lastPhotoUpload or lastMonitoringWeeksUpdate when finished
  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, null, null, async () => {
    try {
      if (weekId) {
        await firebase
          .firestore()
          .doc(`users/${uid}`)
          .update({ lastMonitoringWeeksUpdate: new Date() });
      } else {
        await firebase.firestore().doc(`users/${uid}`).update({ lastPhotoUpload: new Date() });
      }
    } catch (error) {
      console.error("Could not update lastPhotoUpload", error);
    }
  });

  return uploadTask;
};

export const deleteUrl = (url: string) => firebase.storage().refFromURL(url).delete();

export const likePhoto = (uid: string, fullPath: string) =>
  firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      likedPhotos: firebase.firestore.FieldValue.arrayUnion(fullPath),
      lastCoaching: new Date(),
    });

export const getPhotos = async (uid?: string, weekId?: string) => {
  if (!uid) {
    return [];
  }

  const path = weekId ? `users/${uid}/weeks/${weekId}/uploads` : `users/${uid}/uploads`;

  const list = await firebase.storage().ref(path).listAll();

  return Promise.all(
    list.items.map<Promise<Photo>>(async (item) => {
      const meta = await item.getMetadata();
      const fullPath = item.fullPath;
      const url = await item.getDownloadURL();
      const dateCreated = new Date(meta.updated);

      const isVideo =
        /video/.test(meta.contentType) ||
        [".mov", ".avi", ".mpg", ".mpeg", ".webm"].some((type) => url.toLowerCase().includes(type));
      return {
        fullPath,
        url,
        isVideo,
        dateCreated,
      };
    }),
  ).then((photos) => photos.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime()));
};

export const addDailyMessage = (uid: string, message: DailyMessage) =>
  firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      dailyMessages: firebase.firestore.FieldValue.arrayUnion(message),
      lastCoaching: new Date(),
    });

export const deleteDailyMessage = async (uid: string, message: DailyMessage) => {
  const doc = await firebase.firestore().doc(`users/${uid}`).get();

  const user = doc.data();

  if (!user) {
    console.error(`Could not get user ${uid}`);
    return Promise.reject();
  }

  return firebase
    .firestore()
    .doc(`users/${uid}`)
    .update({
      dailyMessages: mapUser(uid, user).dailyMessages.filter((m) => m.id !== message.id),
      lastCoaching: new Date(),
    });
};

export const addTemplate = (template: Template) =>
  firebase.firestore().doc(`templates/${template.id}`).set(template);

export const updateTemplate = (template: Template) =>
  firebase.firestore().doc(`templates/${template.id}`).update(template);

export const deleteTemplate = (template: Template) =>
  firebase.firestore().doc(`templates/${template.id}`).delete();

export const addRecipe = (recipe: Recipe) =>
  firebase.firestore().doc(`recipes/${recipe.id}`).set(recipe);

export const updateRecipe = (recipe: Recipe) =>
  firebase.firestore().doc(`recipes/${recipe.id}`).update(recipe);

export const deleteRecipe = async (recipe: Recipe) =>
  firebase.firestore().doc(`recipes/${recipe.id}`).delete();

export const uploadRecipePhoto = async (file: File) => {
  const res = await firebase.storage().ref(`recipes/${v4()}-${file.name}`).put(file);

  return res.ref.getDownloadURL();
};

export const deleteUser = async (userId: string) => {
  console.log("Delete user", userId);
  return euFunctions().httpsCallable("deleteUser")({ userId });
};
