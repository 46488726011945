import {
  Card,
  CardContent,
  CardMedia,
  createStyles,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { Recipe } from "../../models/recipe";

type Props = {
  recipe: Recipe;
  onOpenRecipe: (recipe: Recipe) => void
};

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      height: "100%",
      cursor: "pointer"
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
  }),
);

export const RecipeCard: React.FC<Props> = ({ recipe, onOpenRecipe }) => {
  const classes = useStyles();

  const handleOpenRecipe = () => {
    onOpenRecipe(recipe)
  }

  return (
    <Card className={classes.card} onClick={handleOpenRecipe} elevation={0}>
      <CardMedia className={classes.media} image={recipe.photo} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {recipe.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {recipe.text}
        </Typography>
      </CardContent>
    </Card>
  );
};
