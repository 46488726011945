import { CircularProgress } from "@material-ui/core";
import React from "react";
import styles from "./LoadingIndicator.module.css";

export const LoadingIndicator: React.FC = () => {
  return (
    <div className={styles.root}>
      <CircularProgress />
    </div>
  );
};
