import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { useTemplates } from "../../hooks/templates";
import { TemplateCategory } from "../../models/template";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/i18n";

type Props = {
  onSubmit: (text: string) => Promise<void>;
  className?: string;
};

export const MessageInput: React.FC<Props> = ({ onSubmit, className }) => {
  const templates = useTemplates();
  const [pending, setPending] = useState(false);
  const [text, setText] = useState<string>("");

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setText(value);

  const onSelectTemplate = ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
    const template = templates.find((template) => template.id === value);
    setText(template ? template.text : text);
  };

  const handleSubmit = async () => {
    try {
      setPending(true);
      await onSubmit(text);
      setText("");
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  return (
    <Card className={className} elevation={0}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={10}>
            <TextField
              fullWidth
              label="Nachricht"
              multiline
              value={text}
              onChange={onChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="template-select-label">Vorlagen</InputLabel>
              <Select
                defaultValue=""
                labelId="template-select-label"
                id="template-select"
                label="Vorlagen"
                onChange={onSelectTemplate}
              >
                {templates
                  .filter(
                    (template) =>
                      template.category === TemplateCategory.FOOD_AUDIT ||
                      template.category === TemplateCategory.NONE,
                  )
                  .map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={handleSubmit} disabled={!text || pending}>
          Nachricht hinzufügen
        </Button>
      </CardActions>
    </Card>
  );
};
