import { Grid, Typography } from "@material-ui/core";
import React from "react";
import image from "../../../../assets/greg.svg";

export const NotReadyStep: React.FC = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <img src={image} width={150} alt="" style={{ margin: "30px 0 10px 0" }} />
      <Typography variant="h4" gutterBottom>
        Soweit sind wir noch nicht
      </Typography>
      <Typography variant="subtitle1">
        Es ist wichtig, dass wir gemeinsam Phase nach Phase gehen, um alle Punkte genau zu
        verstehen.
      </Typography>
    </Grid>
  );
};
