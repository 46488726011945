import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import React, { ChangeEvent, useState } from "react";
import { useTemplates } from "../../hooks/templates";
import { TemplateCategory } from "../../models/template";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/i18n";
import styles from "./AccordionInput.module.css";

type Props = {
  title: string;
  onSave: (text: string) => Promise<void>;
  defaultText?: string;
  initiallyExpanded?: boolean;
};

export const EditStateIndicator: React.FC<{ complete: boolean }> = ({ complete }) => {
  return (
    <div
      className={clsx(styles.editStateIndicator, {
        [styles.editStateIndicatorComplete]: complete,
      })}
    ></div>
  );
};

export const AccordionInput: React.FC<Props> = ({
  title,
  onSave,
  defaultText,
  initiallyExpanded,
}) => {
  const templates = useTemplates();
  const [pending, setPending] = useState(false);
  const [text, setText] = useState<string>(defaultText || "");
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setText(value);

  const onToggleExpanded = () => {
    setExpanded(!expanded);
  };

  const onSelectTemplate = ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
    const template = templates.find((template) => template.id === value);
    setText(template ? template.text : text);
  };

  const handleSave = async () => {
    try {
      setPending(true);
      await onSave(text);
      setExpanded(false);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  return (
    <Accordion expanded={expanded} onChange={onToggleExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid className={styles.titleGrid} container alignItems="center">
          <EditStateIndicator complete={Boolean(defaultText)} />
          <Typography variant="body2">
            <b>{title}</b>
          </Typography>
          <Typography variant="caption">
            {defaultText ? defaultText.substr(0, 30) + "..." : ""}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <TextField
            value={text}
            onChange={onChange}
            multiline
            rows={10}
            label="Text"
            variant="outlined"
            style={{ marginBottom: "20px" }}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="template-select-label">Vorlagen</InputLabel>
            <Select
              defaultValue=""
              labelId="template-select-label"
              id="template-select"
              label="Vorlagen"
              onChange={onSelectTemplate}
            >
              {templates
                .filter(
                  (template) =>
                    template.category === TemplateCategory.HANDLUNGSEMPFEHLUNG ||
                    template.category === TemplateCategory.NONE,
                )
                .map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button size="small" color="primary" onClick={handleSave} disabled={pending}>
          Speichern
        </Button>
      </AccordionActions>
    </Accordion>
  );
};
