import { Typography } from "@material-ui/core";
import React from "react";
import mindsetImage from "../../../../../assets/kopfsache.svg";
import { PageLayout } from "../../PageLayout";

export const RecipesPage4: React.FC = () => {
  return (
    <PageLayout title="Kopfsache" image={mindsetImage}>
      <Typography paragraph>
        Da die Veränderung und Umstellung im Kopf schon Herausforderung genug ist, habe ich
        versucht, die Auswahl und die Zubereitung der Mahlzeiten so einfach wie möglich zu gestalten
        – sie sind:
      </Typography>
      <Typography>
        <strong>Individuell</strong>
      </Typography>
      <Typography paragraph>
        Ich habe bewusst auf klassische Rezepte mit Mengenangaben und Kochzeiten verzichtet.
        Geschmäcker sind zu verschieden. Such Dir einfach aus dem Angebot heraus, was Dir schmeckt –
        sei phantasievoll!
      </Typography>
      <Typography>
        <strong>Einfach</strong>
      </Typography>
      <Typography paragraph>
        Die Gerichte sind ganz simple und einfach zu kochen, damit sie funktionieren und schmecken.
        Wenn Du Kocherfahrung hast, sei so kreativ, wie Du magst und nutze das Modul als Vorlage.
      </Typography>
      <Typography>
        <strong>Schnell</strong>
      </Typography>
      <Typography paragraph>
        Ich vermute, dass niemand die Zeit hat, stundenlag am Herd zu stehen. Daher dauert die
        Zubereitung der Mahlzeiten maximal 30 Minuten.
      </Typography>
      <Typography>
        <strong>Skalierbar</strong>
      </Typography>
      <Typography paragraph>
        Die Mahlzeiten lassen sich direkt in größeren Mengen zubereiten – somit hast Du etwas für
        den nächsten Tag oder zum Einfrieren.
      </Typography>
      <Typography>
        <strong>Abwechslungsreich</strong>
      </Typography>
      <Typography paragraph>
        Durch den modularen Aufbau findest Du dutzende Variationen – es wird Dir nie langweilig.
      </Typography>
      <Typography>
        <strong>Spezifisch</strong>
      </Typography>
      <Typography paragraph>
        Durch die Kennzeichnungen kannst Du Deine Mahlzeiten konkret auf Deine Ziele ausrichten.
      </Typography>
    </PageLayout>
  );
};
