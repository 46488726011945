import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { ChangeEvent, useState } from "react";
import { Template, TemplateCategory } from "../../models/template";
import { showErrorToast } from "../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE, getTemplateCategoryText } from "../../utils/i18n";

type Props = {
  onSave: (template: Template) => Promise<void>;
  onDelete: () => Promise<void>;
  template: Template;
  expanded: boolean;
  toggleExpanded: AccordionProps["onChange"];
};

export const TemplateEditor: React.FC<Props> = ({
  template,
  onSave,
  onDelete,
  expanded,
  toggleExpanded,
}) => {
  const [pending, setPending] = useState(false);
  const [name, setName] = useState<string>(template.name);
  const [text, setText] = useState<string>(template.text);
  const [category, setCategory] = useState<TemplateCategory>(template.category);

  const onChange = (setter: typeof setName) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setter(value);

  const onChangeCategory = ({ target: { value } }: ChangeEvent<{ value: unknown }>) =>
    setCategory(value as TemplateCategory);

  const handleSave = async () => {
    try {
      setPending(true);
      await onSave({
        ...template,
        name,
        text,
        category,
      });
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  const handleDelete = async () => {
    try {
      setPending(true);
      await onDelete();
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  return (
    <Accordion key={template.id} expanded={expanded} onChange={toggleExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container justify="space-between" alignItems="center">
          <Typography>{template.name}</Typography>
          <Typography variant="caption">{getTemplateCategoryText(template.category)}</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <TextField
            value={name}
            onChange={onChange(setName)}
            label="Name"
            variant="outlined"
            disabled={pending}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            value={text}
            onChange={onChange(setText)}
            multiline
            rows={10}
            label="Text"
            variant="outlined"
            disabled={pending}
            style={{ marginBottom: "20px" }}
          />
          <FormControl variant="outlined">
            <InputLabel id="category-select-label">Kategorie</InputLabel>
            <Select
              defaultValue=""
              labelId="category-select-label"
              id="category-select"
              label="Kategorie"
              value={category}
              onChange={onChangeCategory}
              disabled={pending}
              style={{ marginBottom: "20px" }}
            >
              {Object.values(TemplateCategory)
                .filter((t) => t !== TemplateCategory.NONE)
                .map((templateCategory) => (
                  <MenuItem key={templateCategory} value={templateCategory}>
                    {getTemplateCategoryText(templateCategory)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button size="small" onClick={handleDelete} disabled={pending} style={{ color: "red" }}>
          Vorlage löschen
        </Button>
        <Button size="small" color="primary" onClick={handleSave} disabled={pending}>
          Speichern
        </Button>
      </AccordionActions>
    </Accordion>
  );
};
