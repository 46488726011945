import { Typography } from "@material-ui/core";
import React from "react";
import challengesImage from "../../../../../assets/challenges.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

export const MindsetPage5: React.FC = () => {
  return (
    <PageLayout title="Herausforderungen und Rückschläge" image={challengesImage}>
      <Typography>
        <strong>Herausforderungen</strong>
      </Typography>
      <Typography>
        Du selbst kennst Deine „Schwächen“ am besten. Du selbst weißt, woran es in der Vergangenheit
        gelegen hat, dass Du nicht erreicht hast, was Du Dir vorgenommen hast.
      </Typography>
      <Typography paragraph>
        Und die Person, die Dich am besten anlügen kann, bist Du selbst. Es schmerzt manchmal ein
        wenig, aber in dieser Phase musst Du <strong>100% ehrlich</strong> zu Dir selbst sein und
        diese Punkte identifizieren. Das macht den Weg in der Folge einfacher und erfolgreich.
      </Typography>
      <Typography>
        <strong>Rückschläge</strong>
      </Typography>
      <Typography>
        Nobody is perfect. Natürlich passiert es, dass Du vom geplanten Weg abkommen kannst.
      </Typography>
      <Typography paragraph>
        Du kannst die letzte Entscheidung nicht rückgängig machen, aber Du kannst die nächste
        Entscheidung wieder so treffen, dass diese auf Deinen Weg führt.
      </Typography>
      <Typography>
        <strong>Ganz wichtig hier:</strong>
      </Typography>
      <Checklist
        items={[
          "Sei ehrlich zu Dir selbst.",
          "Versuche nicht in die „jetzt ist es auch schon egal, jetzt mache ich weiter so“ Falle zu tappen. Du kannst nur die nächste Entscheidung beeinflussen.",
          "Selbstvorwürfe passieren in dem Augenblick schnell, helfen Dir aber nicht weiter. Bleib positiv und schaue auf die nächste Entscheidung.",
        ]}
      />
    </PageLayout>
  );
};
