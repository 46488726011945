import { Box, Button, LinearProgress, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import FoodImage from "../../assets/food-audit.svg";
import { uploadPhoto } from "../../firebase";
import { useAuth } from "../../hooks/auth";
import { showErrorToast, showSuccessToast } from "../../utils/app-toast";
import { compress } from "../../utils/compressor";
import styles from "./PhotoUpload.module.css";

type Props = {
  onUpload: (urls: string[]) => void;
  weekId?: string;
};

enum Messages {
  FILE_LIMIT_EXCEED = "FILE_LIMIT_EXCEED",
  FILE_ADDED = "FILE_ADDED",
  DROP_REJECTED = "DROP_REJECTED",
  FILE_REMOVED = "FILE_REMOVED",
}

enum UploadState {
  IDLE = "IDLE",
  COMPRESSING = "COMPRESSING",
  UPLOADING = "UPLOADING",
}

type ProgressMap = {
  [id: string]: {
    total: number;
    transferred: number;
  };
};

const getProgress = (progressMap: ProgressMap) => {
  const transferered = Object.keys(progressMap).reduce(
    (acc, key) => acc + progressMap[key].transferred,
    0,
  );

  const total = Object.keys(progressMap).reduce((acc, key) => acc + progressMap[key].total, 0);

  return total ? (transferered / total) * 100 : 0;
};

export const PhotoUpload: React.FC<Props> = ({ onUpload, weekId }) => {
  const { user } = useAuth();
  const [key, setKey] = useState(0);
  const [photos, setPhotos] = useState<File[]>([]);
  const [progressMap, setProgressMap] = useState<ProgressMap>({});
  const [uploadState, setUploadState] = useState(UploadState.IDLE);

  const progress = getProgress(progressMap);

  const onUploadPhotos = async () => {
    if (!user) {
      return;
    }

    try {
      // Compress files
      setUploadState(UploadState.COMPRESSING);
      const compressed = await Promise.all(photos.map(compress));

      // Upload all files and monitor progress
      setUploadState(UploadState.UPLOADING);
      const tasks = await Promise.all(
        compressed.map((photo) => {
          const task = uploadPhoto(user.uid, photo, weekId);

          task.on("state_changed", (snapshot: any) => {
            setProgressMap((state) => ({
              ...state,
              [photo.name]: {
                total: snapshot.totalBytes,
                transferred: snapshot.bytesTransferred,
              },
            }));
          });

          return task;
        }),
      );

      // Collect URLs
      const urls = await Promise.all(tasks.map((task) => task.ref.getDownloadURL()));

      onUpload(urls);

      if (photos.length === 1) {
        showSuccessToast("Dein Foto wurde erfolgreich hochgeladen.");
      } else if (photos.length > 1) {
        showSuccessToast("Deine Fotos wurden erfolgreich hochgeladen.");
      }

      // HACK: Reset DropzoneArea by forcing React to re-render the component
      setKey(key + 1);
    } catch (error) {
      console.error(error);
      showErrorToast(
        "Dein(e) Foto(s) konnte(n) nicht hochgeladen werden. Bitte versuche es noch einmal.",
      );
    } finally {
      setUploadState(UploadState.IDLE);
      setProgressMap({});
    }
  };

  const onAlert = (message: string) => {
    if (message.includes(Messages.FILE_LIMIT_EXCEED)) {
      showErrorToast("Bitte wähle maximal drei Fotos aus.", {
        autoClose: 5000,
      });
    }

    if (message.includes(Messages.DROP_REJECTED)) {
      showErrorToast(
        "Dein Foto ist etwas zu groß für uns. Bitte wähle eine Datei, die kleiner als 20 Megabyte ist.",
      );
    }
  };

  return (
    <div className={styles.root}>
      <DropzoneArea
        key={key}
        onChange={setPhotos}
        dropzoneText="Ziehe Deine Bilder auf diese Schaltfläche oder klicke hier"
        // Hide preview during upload
        previewType={uploadState === UploadState.IDLE ? "below" : "none"}
        previewText="&nbsp;"
        showFileNames={false}
        getCols={() => 3}
        // Allow files up to 20mb
        maxFileSize={20000000}
        filesLimit={3}
        // We handle all alerts
        showAlerts={false}
        //@ts-ignore because type is missing
        onAlert={onAlert}
        //@ts-ignore because type is missing
        Icon={() => <img src={FoodImage} height={160} alt="" />}
        // Use custom messages to handle alerts
        getDropRejectMessage={() => Messages.DROP_REJECTED}
        getFileAddedMessage={() => Messages.FILE_ADDED}
        getFileRemovedMessage={() => Messages.FILE_REMOVED}
        getFileLimitExceedMessage={() => Messages.FILE_LIMIT_EXCEED}
        dropzoneClass={styles.dropzone}
        classes={{ root: styles.con }}
      />
      {uploadState !== UploadState.IDLE && (
        <Box display="flex" alignItems="center" className={styles.progress}>
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              progress,
            )}%`}</Typography>
          </Box>
        </Box>
      )}
      {uploadState === UploadState.IDLE && photos.length > 0 && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={styles.uploadButton}
          onClick={onUploadPhotos}
        >
          Jetzt hochladen
        </Button>
      )}
    </div>
  );
};
