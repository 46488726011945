import { createStyles, makeStyles, Paper, Tab, Theme } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { lazy, Suspense } from "react";
import { AnalysisInput } from "../../../../../components/AnalysisInput/AnalysisInput";
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator";
import { PerformanceUser } from "../../../../../models/performance-user";

const AnalysisPdfViewer = lazy(
  () => import("../../../../../components/AnalysisPdf/AnalysisPdfViewer"),
);

const AnalysisPdfDownload = lazy(
  () => import("../../../../../components/AnalysisPdf/AnalysisPdfDownload"),
);

enum Tabs {
  INPUT = "INPUT",
  PREVIEW = "PREVIEW",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      marginTop: 20,
      padding: 0,
    },
    downloadButton: {
      margin: "20px 0 20px 0",
    },
  }),
);

export const AnalysisTab: React.FC<{ user: PerformanceUser }> = ({ user }) => {
  const [value, setValue] = React.useState(Tabs.INPUT);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: Tabs) => {
    setValue(newValue);
  };

  if (!user) {
    return null;
  }

  return (
    <TabContext value={value}>
      <Paper>
        <TabList onChange={handleChange} textColor="primary">
          <Tab label="Empfehlung" value={Tabs.INPUT} />
          <Tab label="PDF Vorschau" value={Tabs.PREVIEW} />
        </TabList>
      </Paper>
      <TabPanel className={classes.tabPanel} value={Tabs.INPUT}>
        <AnalysisInput user={user} />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={Tabs.PREVIEW}>
        <Suspense fallback={<LoadingIndicator />}>
          <div className={classes.downloadButton}>
            <AnalysisPdfDownload user={user} text="PDF herunterladen" />
          </div>
          <AnalysisPdfViewer user={user} />
        </Suspense>
      </TabPanel>
    </TabContext>
  );
};
