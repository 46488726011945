import { createStyles, makeStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      listStyleType: "none",
      margin: "0",
      padding: "0",
    },
    item: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
    },
    checkmark: {
      marginRight: "10px",
    },
  }),
);

type Props = {
  items: string[];
};

export const Checklist: React.FC<Props> = ({ items }) => {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {items.map((item) => (
        <li key={item} className={classes.item}>
          <Check color="primary" className={classes.checkmark} /> {item}
        </li>
      ))}
    </ul>
  );
};
