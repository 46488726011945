import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import goalsImage from "../../../../../assets/goals.svg";
import { PageLayout } from "../../PageLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    literal: {
      fontSize: "24px",
      fontWeight: "bold",
      marginRight: "10px",
      color: theme.palette.primary.main,
    },
    subheadline: {
      margin: "30px 0 0px 0",
    },
  }),
);

export const MindsetPage6: React.FC = () => {
  const classes = useStyles();
  return (
    <PageLayout title="Ziele" image={goalsImage}>
      <Typography>
        Das SMART Modell kann dir helfen deine Ziele zu definieren. Sie sollten an folgenden
        Kriterien festgemacht werden:
      </Typography>
      <br />
      <Typography>
        <span className={classes.literal}>S</span> <br /> <strong>Specific | Spezifisch</strong> -
        Versuche Dein Ziel so klar wie möglich zu formulieren, damit es nicht nur ein vager Wunsch
        bleibt.
      </Typography>
      <Typography>
        <span className={classes.literal}>M</span> <br />
        <strong>Measurable | Messbar</strong> - Deine Ziele sollten messbar sein, quantitativ
        und/oder qualitativ. Du solltest Kriterien definieren an denen Du erkennst, dass Du Deine
        Ziele erreicht hast.
      </Typography>
      <Typography>
        <span className={classes.literal}>A</span> <br /> <strong>Attractive | Attraktiv</strong> -
        Deine Ziele sollten für Dich attraktiv und wertvoll, also mit Deiner Motivation im Einklang
        sein, damit Du mit Freude dabei bleibst.
      </Typography>
      <Typography>
        <span className={classes.literal}>R</span> <br /> <strong>Realistic | Realistisch</strong> -
        Deine Ziele sollten realistisch sein, damit Du den Fortschritt und Erfolg spürst und nicht
        demotiviert wirst.
      </Typography>
      <Typography>
        <span className={classes.literal}>T</span> <br /> <strong>Time framed | Zeitrahmen</strong>{" "}
        - Deine Ziele sollten klare Fristen und Zeitrahmen beinhalten, damit Du nicht Gefahr läufst,
        Deine Aktivitäten immer weiter nach hinten zu verschieben.
      </Typography>
    </PageLayout>
  );
};
