import { deDE } from "@material-ui/core/locale";
// https://github.com/mui-org/material-ui/issues/13394
import {
  // unstable_createMuiStrictModeTheme as createMuiTheme,
  createMuiTheme,
} from "@material-ui/core/styles";

const PRIMARY = "#60B736";
const SECODARY = "#0084DD";

const BORDER_RADIUS = 8;
const BORDER_COLOR = "1px solid rgba(103 ,201, 56, 0.3)";

// Make sure to copy this file to web/app
export const theme = createMuiTheme(
  {
    palette: {
      // type: "dark",
      primary: {
        main: PRIMARY,
        contrastText: "#fff",
      },
      secondary: {
        main: SECODARY,
      },
      text: {
        primary: "#121212",
        secondary: "#121212",
      },
    },
    typography: {
      fontFamily: '"Nunito", Sans-Serif',
    },
    overrides: {
      MuiPaper: {
        root: {
          border: BORDER_COLOR,
          borderRadius: BORDER_RADIUS,
          overflow: "hidden",
        },
        rounded: {
          borderRadius: BORDER_RADIUS,
          overflow: "hidden",
        },
      },
      MuiCard: {
        root: {
          border: BORDER_COLOR,
          borderRadius: BORDER_RADIUS,
          overflow: "hidden",
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: BORDER_RADIUS,
        },
      },
      MuiButton: {
        root: {
          borderRadius: "100vh",
        },
      },
      MuiTabs: {
        indicator: {
          display: "none",
          backgroundColor: "none",
        },
      },
      MuiTab: {
        root: {
          "&$selected": {
            color: PRIMARY,
          },
        },
        selected: {},
      },
      MuiBreadcrumbs: {
        root: {
          color: PRIMARY,
        },
      },
      MuiPopover: {
        paper: {
          border: "none",
        },
      },
      MuiAccordion: {
        root: {
          borderRadius: BORDER_RADIUS,
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "1px solid #e0e0e0",
          "&$expanded": {
            borderRadius: BORDER_RADIUS,
            border: BORDER_COLOR,
          },
        },
      },
    },
  },
  deDE,
);
