import { Avatar, Badge, makeStyles, Paper, TextField } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { navigate } from "@reach/router";
import React from "react";
import { CoachingStep } from "../../models/coaching-steps";
import { MotivationType } from "../../models/motivation";
import { PerformanceUser } from "../../models/performance-user";
import { buildUserDetailPath } from "../../routes/routes";
import { needsCoaching } from "../../utils/date";
import { getCoachingStepText, getMotivationText } from "../../utils/i18n";
import styles from "./UsersTable.module.css";

type Props = {
  users: PerformanceUser[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    color: theme.palette.type === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
      borderRight: `1px solid ${theme.palette.type === "light" ? "#f0f0f0" : "#303030"}`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${theme.palette.type === "light" ? "#f0f0f0" : "#303030"}`,
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
      outline: "none",
      color: theme.palette.type === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
  },
}));

const columns: GridColDef[] = [
  {
    field: "photoURL",
    headerName: " ",
    width: 80,
    sortable: false,
    renderCell: ({ row, value }) => (
      <Badge
        color="error"
        overlap="circle"
        badgeContent=""
        variant="dot"
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        invisible={!needsCoaching(row.lastCoaching, row.lastUpdate)}
      >
        <Avatar src={value as string} title={row.displayName} />
      </Badge>
    ),
  },
  {
    field: "customName",
    headerName: "Name",
    width: 200,
    valueFormatter: ({ row, value }) => value || row.displayName || row.email || "-",
    sortComparator: (a, b, aParams, bParams) => {
      const aName = aParams.row.customName || aParams.row.displayName || aParams.row.email || "-";
      const bName = bParams.row.customName || bParams.row.displayName || aParams.row.email || "-";
      return aName.toLowerCase().localeCompare(bName.toLowerCase());
    },
  },
  {
    field: "coachingStep",
    headerName: "Phase",
    width: 130,
    sortComparator: (a, b) =>
      getCoachingStepText(a as CoachingStep).localeCompare(getCoachingStepText(b as CoachingStep)),
    valueFormatter: ({ value }) => getCoachingStepText(value as CoachingStep),
  },
  {
    field: "motivation",
    headerName: "Motivation",
    width: 300,
    sortComparator: (a, b) =>
      (a as MotivationType[])
        .map(getMotivationText)
        .sort((a, b) => a.localeCompare(b))
        .join(", ")
        .localeCompare(
          (b as MotivationType[])
            .map(getMotivationText)
            .sort((a, b) => a.localeCompare(b))
            .join(", "),
        ),
    valueFormatter: ({ value }) =>
      (value as MotivationType[])
        .map(getMotivationText)
        .sort((a, b) => a.localeCompare(b))
        .join(", ") || "-",
  },
  {
    field: "lastUpdate",
    headerName: "Letztes Update",
    width: 230,
    type: "dateTime",
    valueFormatter: ({ value }) =>
      value
        ? (value as Date).toLocaleString("de-DE", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "-",
  },
  {
    field: "lastLogin",
    headerName: "Letzter Login",
    width: 230,
    type: "dateTime",
    valueFormatter: ({ value }) =>
      value
        ? (value as Date).toLocaleString("de-DE", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "-",
  },
  {
    field: "lastPhotoUpload",
    headerName: "Letztes Foto",
    width: 230,
    type: "dateTime",
    valueFormatter: ({ value }) =>
      value
        ? (value as Date).toLocaleString("de-DE", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "-",
  },
  {
    field: "lastMindsetUpdate",
    headerName: "Letzte Hausaufgabe",
    width: 230,
    type: "dateTime",
    valueFormatter: ({ value }) =>
      value
        ? (value as Date).toLocaleString("de-DE", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "-",
  },
];

export const UsersTable: React.FC<Props> = ({ users }) => {
  const classes = useStyles();
  const rows = users
    .map((user) => ({ id: user.uid, ...user }))
    .sort((a, b) => (b.lastUpdate?.getTime() || 0) - (a.lastUpdate?.getTime() || 0));

  const filterOptions = createFilterOptions({
    stringify: (option: PerformanceUser) =>
      `${option.customName} ${option.displayName} ${option.email}`,
  });

  return (
    <Paper className={styles.paper}>
      <Autocomplete
        className={styles.search}
        options={rows}
        getOptionLabel={(option) => option.customName || option.displayName || option.email}
        filterOptions={filterOptions}
        onChange={(_, value) => {
          if (value) {
            navigate(buildUserDetailPath(value.uid));
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="Benutzer suchen" margin="normal" variant="outlined" />
        )}
      />
      <div className={styles.dataGridWrapper}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={10}
          className={classes.root}
          onRowClick={(params) => navigate(buildUserDetailPath(String(params.row.id)))}
        />
      </div>
    </Paper>
  );
};
