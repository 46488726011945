import {
  Button,
  Card,
  CardActions,
  CardContent,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Tab,
  TextField,
  Theme,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { ChangeEvent, useEffect, useState } from "react";
import { HomeworkCard } from "../../../../../components/HomeworkCard/HomeworkCard";
import { LoadingIndicator } from "../../../../../components/LoadingIndicator/LoadingIndicator";
import { PhotoGrid, PhotoGridVariant } from "../../../../../components/PhotoGrid/PhotoGrid";
import { addDailyMessage, deleteDailyMessage, likePhoto } from "../../../../../firebase";
import { usePhotos } from "../../../../../hooks/photos";
import { useTemplates } from "../../../../../hooks/templates";
import { updateOutroText } from "../../../../../hooks/users";
import {
  DailyMessage,
  MonitoringWeek,
  PerformanceUser,
} from "../../../../../models/performance-user";
import { Photo } from "../../../../../models/photo";
import { TemplateCategory } from "../../../../../models/template";
import { showErrorToast } from "../../../../../utils/app-toast";
import { DEFAULT_ERROR_MESSAGE } from "../../../../../utils/i18n";

const WeekTab: React.FC<{
  week: MonitoringWeek;
  user: PerformanceUser;
}> = ({ user, user: { lastMonitoringWeeksUpdate }, week }) => {
  const { photos, updatePhotos, loading: photosLoading } = usePhotos(user.uid, week.id);

  useEffect(() => {
    updatePhotos();
    // Re-fetch photos if a new picture has been added or liked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMonitoringWeeksUpdate]);

  const onPhotoLike = async (photo: Photo) => likePhoto(user.uid, photo.fullPath);

  const onAddDailyMessage = (message: DailyMessage) => addDailyMessage(user.uid, message);

  const onDeleteDailyMessage = (message: DailyMessage) => deleteDailyMessage(user.uid, message);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <HomeworkCard title="Einkaufsliste" text={week.shoppingList} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <HomeworkCard title="Tagebuch" text={week.thoughts} />
      </Grid>
      <Grid item xs={12}>
        {photosLoading ? (
          <LoadingIndicator />
        ) : (
          <PhotoGrid
            user={user}
            photos={photos}
            onLike={onPhotoLike}
            onAddDailyMessage={onAddDailyMessage}
            onDeleteDailyMessage={onDeleteDailyMessage}
            variant={PhotoGridVariant.COACHING_VIEW}
            weekId={week.id}
          />
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      marginTop: 20,
      padding: 0,
    },
  }),
);

export const MonitoringTab: React.FC<{ user: PerformanceUser }> = ({ user }) => {
  const classes = useStyles();
  const templates = useTemplates();
  const [tabIndex, setTabIndex] = useState<string>(String(user.monitoringWeeks.length - 1));
  const [outroText, setOutroText] = useState(user.outroPageText);
  const [pending, setPending] = useState(false);

  if (!user || user.monitoringWeeks.length <= 0) {
    return null;
  }

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setTabIndex(newValue);
  };

  const handleOutroTextChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setOutroText(value);

  const handleSelectTemplate = ({ target: { value } }: ChangeEvent<{ value: unknown }>) => {
    const template = templates.find((template) => template.id === value);
    setOutroText(template ? template.text : outroText);
  };

  const handleSaveOutroText = async () => {
    try {
      setPending(true);
      await updateOutroText(user.uid, outroText);
    } catch (error) {
      console.error(error);
      showErrorToast(DEFAULT_ERROR_MESSAGE);
    } finally {
      setPending(false);
    }
  };

  return (
    <TabContext value={String(tabIndex)}>
      <Paper>
        <TabList onChange={handleChange} textColor="primary">
          {user.monitoringWeeks.map((week, index) => (
            <Tab key={week.id} label={`Woche ${index + 1}`} value={String(index)} />
          ))}
          <Tab label={"Verabschiedung"} value={"outro"} />
        </TabList>
      </Paper>
      {user.monitoringWeeks.map((week, index) => (
        <TabPanel key={week.id} className={classes.tabPanel} value={String(String(index))}>
          <WeekTab user={user} week={week} />
        </TabPanel>
      ))}
      <TabPanel className={classes.tabPanel} value={"outro"}>
        <Card>
          <CardContent>
            <Grid container direction="column">
              <TextField
                value={outroText}
                onChange={handleOutroTextChange}
                multiline
                rows={10}
                label="Text"
                variant="outlined"
                style={{ marginBottom: "20px" }}
              />
              <FormControl fullWidth variant="outlined">
                <InputLabel id="template-select-label">Vorlagen</InputLabel>
                <Select
                  defaultValue=""
                  labelId="template-select-label"
                  id="template-select"
                  label="Vorlagen"
                  onChange={handleSelectTemplate}
                >
                  {templates
                    .filter(
                      (template) =>
                        template.category === TemplateCategory.OUTRO ||
                        template.category === TemplateCategory.NONE,
                    )
                    .map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              onClick={handleSaveOutroText}
              disabled={outroText === user.outroPageText || pending}
            >
              Speichern
            </Button>
          </CardActions>
        </Card>
      </TabPanel>
    </TabContext>
  );
};
