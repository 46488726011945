import { Grid, Typography } from "@material-ui/core";
import React from "react";
import metabolismImage from "../../../../../assets/metabolism-alt.svg";
import { Checklist } from "../../../../../components/Checklist/Checklist";
import { PageLayout } from "../../PageLayout";

export const MetabolismPage3: React.FC = () => {
  return (
    <PageLayout title="Stoffwechsel II">
      <Typography paragraph>
        Nicht alle Inhaltsstoffe in den Nahrungsmitteln, die wir essen, enden auch in der Form in
        unserem Körper.
      </Typography>
      <Typography paragraph>
        Es gibt Nahrungsmittel, deren Inhaltsstoffe wir sehr gut verdauen können. Wir können die
        Inhaltsstoffe sehr gut aufnehmen und diese wirken sich{" "}
        <strong>begünstigend auf unseren Körper</strong> aus.
      </Typography>
      <Typography paragraph>
        Es gibt aber auch Nahrungsmittel, deren Inhaltsstoffe wir nur teilweise oder gar nicht
        verdauen können. Beim Verdauungsprozess können sich dabei als Nebeneffekt auch{" "}
        <strong>Abfallstoffe</strong> bilden und diese wirken sich belastend auf unseren Körper aus.
      </Typography>
      <Typography paragraph>
        Und hier der aus meiner Sicht wichtigste Satz:{" "}
        <strong>
          Nicht was Du in den Mund tust zählt, sondern was am Ende in der Blutbahn landet!
        </strong>
      </Typography>
      <Grid container justify="center">
        <Grid item>
          <img src={metabolismImage} alt="" />
        </Grid>
      </Grid>
      <Typography style={{ marginTop: 20 }}>
        <strong>Was bedeutet das für die praktische Umsetzung unserer Ernährung?</strong>
      </Typography>
      <Typography>Unser Stoffwechsel setzt sich aus 3 Haushalten zusammen:</Typography>
      <Checklist items={["Energie-Haushalt", "Säure-Basen-Haushalt", "Nährwert-Haushalt"]} />
      <Typography paragraph>
        Diese 3 Haushalte interagieren miteinander und wirken sich aufeinander aus. Nur wenn Du die
        Interaktion der 3 Haushalte verstehst, verstehst Du die{" "}
        <strong>Auswirkung auf Deinen Körper</strong> – und kannst dementsprechend Entscheidungen
        bezüglich Deiner Ernährung treffen.
      </Typography>
    </PageLayout>
  );
};
